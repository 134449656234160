import React, { useState } from 'react'
import { BridgeLocation } from '@/entities/bridgeLocation'
import BridgeLocationIcon from '../BridgeLocationIcon'
import BridgeLocationDropdown, { SelectOption } from './BridgeLocationDropdown'
import { useUpdateEffect } from 'usehooks-ts'

interface BridgeSelectorProps {
  hideOption?: BridgeLocation
  defaultLocation?: BridgeLocation // New prop to specify the default location
  onSelect?: (selectedItem: BridgeLocation) => void
}

const BridgeSelector: React.FC<BridgeSelectorProps> = ({ hideOption, defaultLocation, onSelect}) => {
  const [selectedItem, setSelectedItem] = useState<BridgeLocation>(defaultLocation || BridgeLocation.None) // Set the default location value
 
  const handleItemClick = (selected: BridgeLocation) => {
    setSelectedItem(selected)                             
    if (onSelect) {
      onSelect(selected)
    }
  }

  useUpdateEffect(() => {
    const selectedLocation = Object.entries(BridgeLocation).find(([,value]) => value === defaultLocation)?.[0]
    setSelectedItem(selectedLocation as BridgeLocation)
  },[defaultLocation])

  const bridgeLocationEntries = Object.entries(BridgeLocation).filter(([, value]) => value !== hideOption)
  const changedLocationEntries = Object.entries(BridgeLocation).map(
    ([key, value]) =>
      ({
        label: value,
        value: key,
        icon: <BridgeLocationIcon location={value} />,
      } as SelectOption),
  )
  
  return (
    <div className="flex items-center mx-auto shadow-md rounded-xl" style={{ backgroundColor: '#00AAFF' }}>
      <BridgeLocationDropdown
        onChange={handleItemClick}
        options={bridgeLocationEntries.map(
          ([key, value]) =>
            ({
              label: value,
              value: key,
              icon: <BridgeLocationIcon location={value} />,
            } as SelectOption),
        )}
        changedOptions={changedLocationEntries}
        defaultSelectedValue={selectedItem}   //current selected option
      />
    </div>
  )
}

export default BridgeSelector
