import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../redux/store'
import { BridgeOutGetResponse } from '@/entities/dto/bridgeOut'
import { ShipInfo } from '@/entities/dto/ship'

// Define a type for the slice state
interface GameState {
  bridgeOutResource: BridgeOutGetResponse
  bridgeShips:ShipInfo[]
}

// Define the initial state using that type
const initialState: GameState = {
    bridgeOutResource:  {} as BridgeOutGetResponse,
    bridgeShips: [] as ShipInfo[]
}

export const gameSlice = createSlice({
  name: 'game',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGameResource: (state, action:PayloadAction<BridgeOutGetResponse>) => {
      state.bridgeOutResource = action.payload
    },
    setShips: (state, action:PayloadAction<ShipInfo[]>) => {
        state.bridgeShips = action.payload
      },
  },
})

export const { setGameResource, setShips } =
  gameSlice.actions

// Other code such as selectors can use the imported `RootState` type

export const selectGame = (state: RootState) => state.game

export default gameSlice.reducer
