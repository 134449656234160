import { CHAIN_ID, ENV_LAND_ADDRESS, EXPLORER_URLS, NUMERALS, RPC_URL_MAP } from '@/constants/web3'
import { RoundType } from '@/models/index'

const config = {
  PREVIEW_BUILD: 'https://dev-assets.deepspace.game/land/LandWhitelistBuild',
  STATUS_API_URL: 'https://status.deepspace.game/api',
  GET_HASH_URL: 'https://ethhex.deepspace.game/gethash/',
  WHITELIST_ROUND1: 'https://land.deepspace.game/api/whitelist/round1',
  WHITELIST_ROUND2: 'https://land.deepspace.game/api/whitelist/round2',
  MAX_SPOTS: {
    [RoundType.ROUND1]: 250,
    [RoundType.ROUND2]: 500,
    [RoundType.PUBLIC]: 4150,
  },
  WALLET_LIMIT: {
    [RoundType.ROUND1]: 1,
    [RoundType.ROUND2]: 2,
    [RoundType.PUBLIC]: 8,
  },
  MINT_COST: {
    [RoundType.ROUND1]: 0,
    [RoundType.ROUND2]: 0.02,
    [RoundType.PUBLIC]: 0.04,
  },

  CHAIN_ID,
  NUMERALS,
  RPC_URL: RPC_URL_MAP[CHAIN_ID],
  EXPLORER_URL: EXPLORER_URLS[CHAIN_ID],
  CONNECTOR_LOCAL_STORAGE_KEY: 'connectorId',
  LAND_ADDRESS: ENV_LAND_ADDRESS[CHAIN_ID],
}

export default config
