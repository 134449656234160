import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import { useAppSelector } from 'src/redux/hook'


const ConfirmResourceList = () => {

  const { confirmResource } = useAppSelector((state: any) => state.bridge)
  return (
    <>
      <Wrapper>
        <BackGround></BackGround>
        <List>
          {confirmResource &&
            confirmResource.map((item:any, index:number) => {
              return (
                <ResourceItem key={index}>
                  <Image src={item.img} width={32.5} height={32.5} alt="img" />
                  <Quantity>{item?.quantity}</Quantity>
                </ResourceItem>
              )
            })}
        </List>
      </Wrapper>
    </>
  )
}

export default ConfirmResourceList

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  border: solid 2px #00aeee;
  position: relative;
`
const BackGround = styled.div`
  width: 100%;
  height: 46px;
  background: black;
  opacity: 0.3;
`
const List = styled.div`
  width: 100%;
  height: 47px;
  position: absolute;
  display: flex;
  align-items: center;
  top: 0px;
`
const ResourceItem = styled.div`
  position: relative;
  width: 35px;
  height: 35px;
  border: solid 1px #00aeee;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 5px;
`
const Quantity = styled.span`
  position: absolute;
  right: 4px;
  bottom: -3px;
  font-family: monospace;
`
