export enum RoundType {
    ROUND1 = 'round1',
    ROUND2 = 'round2',
    PUBLIC = 'public',
}

export interface MintPeriods {
    [RoundType.ROUND1]: RoundPeriod,
    [RoundType.ROUND2]: RoundPeriod,
    [RoundType.PUBLIC]: RoundPeriod,
}

export interface MintSupply {
    [RoundType.ROUND1]: number,
    [RoundType.ROUND2]: number,
    [RoundType.PUBLIC]: number,
}

export interface SpotsLeft {
    totalSupply: number | null,
    [RoundType.ROUND1]: number | null,
    [RoundType.ROUND2]: number | null,
    [RoundType.PUBLIC]: number | null,
}

export interface RoundPeriod {
    isStarted: boolean,
    start: number,
    end: number,
}

export interface RemainingTime {
    days: number,
    hours: number,
    min: number,
    sec: number,
}

export interface TimeRemainingPerRound {
    [RoundType.ROUND1]: RemainingTime | null,
    [RoundType.ROUND2]: RemainingTime | null,
    [RoundType.PUBLIC]: RemainingTime | null,
}