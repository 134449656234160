import { Config } from '@/config/config'
import { useNetwork } from 'wagmi'

export function useContractAddress(): string | undefined {
  const { chain } = useNetwork()
  if (chain?.id === Config.chainIdBsc()) {
    return Config.contractAddressTokenBsc()
  } else if (chain?.id === Config.chainIdEth()) {
    return Config.contractAddressTokenEth()
  } else {
    console.log('Failed to detect contract. Returning')
    return undefined
  }
}
