import React, { useEffect } from 'react'
import styled from 'styled-components'
import { TfiMenu } from 'react-icons/tfi'
import Link from 'next/link'
import { useNetwork } from 'wagmi'
import { toast } from 'react-toastify';
import EthIcon from '../Svg/Icons/EthIcon'
import BnbIcon from '../Svg/Icons/BnbIcon'
import { navigationEntries } from '@/constants/navigation'
import WalletConnectBtn from '@/components/WalletConnectBtn/WalletConnectBtn'

export default function Header() {
  const { chain } = useNetwork()
  useEffect(()=>{
    //if (chain)toast(`Connected to ${chain?.name}`, {autoClose: 10000})
  }, [chain])
  const getChainLabel = (chain: string) => {
    switch (chain) {
      case 'ETH':
        return 'Ethereum Powered'
      case 'BNB':
        return 'BNB Chain Powered'
      default:
        return ''
    }
  }

  const getChainIcon = (chain: string) => {
    const size = '80%'

    switch (chain) {
      case 'ETH':
        return <EthIcon height={size} />
      case 'BNB':
        return <BnbIcon height={size} />
      default:
        return <></>
    }
  }

  const navEntries = navigationEntries.map((e) => (
    <li key={e.href}>
      <Link href={e.href} className={`${e.isActive ? 'active' : ''} ${e.chain ? 'indicator' : ''}`}>
        {e.label}
        {e.chain && (
          <span
            className={`transition-all indicator-item indicator-bottom badge rounded-full
                                    w-7 h-7 p-0 ${e.isActive ? 'badge-primary' : 'opacity-0'}`}
          >
            <span
              className="flex items-center justify-center w-full h-full tooltip tooltip-bottom"
              data-tip={getChainLabel(e.chain)}
            >
              {getChainIcon(e.chain)}
            </span>
          </span>
        )}
      </Link>
    </li>
  ))

  return (
    <Style className="fixed top-0 z-50 h-16">
      <div className="navbar">
        <div className="navbar-start">
          <div className="flex-none lg:hidden">
            <label htmlFor="drawer" className="btn btn-ghost">
              <TfiMenu fontSize={20} />
            </label>
          </div>

          <div className="indicator">
            <div className="dps-logo" />
          </div>
        </div>

        <div className="hidden navbar-center lg:flex">
          <ul className="gap-2 px-1 menu menu-horizontal">{navEntries}</ul>
        </div>

        <div className="navbar-end">
          <WalletConnectBtn />
        </div>
      </div>
    </Style>
  )
}

const Style = styled.div`
  width: 100%;
  background: url('/images/ui/header_wallpaper.webp') center top;
  background-size: cover;

  .navbar {
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100%;
    padding: 0 1rem;
    background-color: #0d0b10cc;
    border-bottom: 1px solid #351268;
  }

  .dps-logo {
    height: 2.5rem;
    width: 15rem;
    background: url('/images/icons/deepspace.svg') no-repeat left center;

    @media (max-width: 600px) {
      background: url('/images/icons/dps.svg') no-repeat left center;
      width: 2.5rem;
    }
  }
  @media (max-width: 340px) {
    .btn-md{
      padding-left: 0.5rem;
      padding-right: 0.5rem;      
    }
  }

  .indicator:hover .indicator-item {
    opacity: 100;
  }
`
