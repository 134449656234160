import { Config } from '@/config/config'
import RESOURCES_ABI from '@/config/abis/DPS_Resources.json'
import { useState } from 'react'
import { RESOURCE_LIST } from '@/utils/resourceHelper'
import { useAccount, useContractRead } from 'wagmi'
import { BridgeLocation } from '@/entities/bridgeLocation'
import { useAppSelector } from 'src/redux/hook'

export function useResources (enabled: boolean) {
  const { chainFromStatus, chainToStatus } = useAppSelector((state: any) => state.bridge)
  const [value, setValue] = useState<any>([])
  const { address: walletAddress } = useAccount()
  const address = Config.contractAddressResourcesBsc()
  const addresses = Array.from({ length: 8 }, () => walletAddress)
  const resourceIds = Array.from({ length: 8 }, (_, index) => index)
  useContractRead({
    address: address as `0x${string}`,
    abi: RESOURCES_ABI,
    functionName: 'balanceOfBatch',
    args: [addresses, resourceIds],
    watch: true,
    enabled: (enabled && walletAddress && address && ((chainFromStatus === BridgeLocation.Game && chainToStatus === BridgeLocation.BSC) ||
    (chainToStatus === BridgeLocation.Game && chainFromStatus === BridgeLocation.BSC))) as boolean,
    onSuccess(data: any) {
      const getResource: string[] = []
      RESOURCE_LIST.map((item: any, index: number) => {
        const netItem = { ...item, quantity: Number(data[index]) }
        getResource.push(netItem)
      })
      setValue(getResource)
    },
    onError(error) {
      console.log('Error', error)
      setValue([])
    },
  })
  return value;
}
