export const RESOURCE_LIST = [
  { img: "/images/resource/Iron.png", pid: 0, type: "Iron" },
  { img: "/images/resource/Seplium.png", pid: 1, type: "Seplium" },
  { img: "/images/resource/Hydrogen.png", pid: 2, type: "Hydrogen" },
  { img: "/images/resource/Fenna.png", pid: 3, type: "Fenna" },
  { img: "/images/resource/Bedasine.png", pid: 4, type: "Bedasine" },
  { img: "/images/resource/Netherite.png", pid: 5, type: "Netherite" },
  { img: "/images/resource/Xanifarium.png", pid: 6, type: "Xanifarium" },
  { img: "/images/resource/Promethium.png", pid: 7, type: "Promethium" },
];
