import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useNetwork, useSwitchNetwork } from 'wagmi'
import detectEthereumProvider from '@metamask/detect-provider'
import Image from 'next/image'
import styled from 'styled-components'

import BridgeSelector from './BridgeSetup/BridgeSelector'
import ArrowRight from '@/components/Svg/Icons/ArrowRight'
import { BridgeLocation } from '@/entities/bridgeLocation'
import BridgeSteps from './BridgeSteps'
import { BridgeStep } from '@/entities/bridgeStep'

import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { setChainFromStatus, setChainToStatus, setSwitchFlag } from '@/features/bridge/bridgeSlice'
import { Config } from '@/config/config'

declare global {
  interface Window {
      ethereum?: any;
  }
}
const Swap = () => {
  const dispatch = useAppDispatch()
  const { chain } = useNetwork()
  const { isLoading, pendingChainId, switchNetworkAsync } =
    useSwitchNetwork()

  const [bridgeTo, setBridgeTo] = useState(BridgeLocation.None)
  const [bridgeFrom, setBridgeFrom] = useState(BridgeLocation.None)
  const [bridgeSteps, setBridgeSteps] = useState(new Array<BridgeStep>())

  const calculateSteps = () => {
    if (bridgeFrom === BridgeLocation.None || bridgeTo === BridgeLocation.None) {
      setBridgeSteps([])
      return
    }

    const newBridgeSteps = new Array<BridgeStep>()

    if (bridgeFrom == BridgeLocation.BSC || bridgeFrom == BridgeLocation.ETH) {
      newBridgeSteps.push({ from: bridgeFrom, to: BridgeLocation.Game })
    }

    if (bridgeTo == BridgeLocation.BSC || bridgeTo == BridgeLocation.ETH) {
      newBridgeSteps.push({ from: BridgeLocation.Game, to: bridgeTo })
    }

    setBridgeSteps(newBridgeSteps)
  }
  const { chainFromStatus, chainToStatus, chainChangeStatus } = useAppSelector((state: any) => state.bridge)
  useEffect(() => {
    if (chainChangeStatus) {
      setBridgeFrom(chainFromStatus)
      setBridgeTo(chainToStatus)
    }
  }, [chainChangeStatus])
  useEffect(() => {
    if (chain?.id && pendingChainId) {
      dispatch(setSwitchFlag(isLoading && chain?.id !== pendingChainId))
    }
  }, [isLoading, chain?.id, pendingChainId, dispatch])

  useEffect(() => {
    function startApp(provider:any) {
      // If the provider returned by detectEthereumProvider isn't the same as
      // window.ethereum, something is overwriting it – perhaps another wallet.
      if (provider !== window.ethereum) {
        toast('Do you have multiple wallets installed?');
      }
      // Access the decentralized web!
    }
    const asyncSwitchNetwork  = async ()  => {
      if (bridgeFrom === 'Game' && bridgeTo === 'ETH' || bridgeFrom === 'ETH') {
        try {
          await switchNetworkAsync?.(Config.chainIdEth())
        }catch(error){
          console.log(JSON.stringify(error))
          toast("You failed to switch to Ethereum.")
        }
      } else if (bridgeFrom === 'Game' && bridgeTo === 'BNB Chain' || bridgeFrom === 'BNB Chain') {
        try {
          await switchNetworkAsync?.(Config.chainIdBsc())
        }catch(error){
          console.log(JSON.stringify(error))
          toast("You failed to switch to BNB Chain.")
        }
      }
      const provider = await detectEthereumProvider()
      if (provider) {
        // From now on, this should always be true:
        // provider === window.ethereum
        startApp(provider) // initialize your app
      } else {
        //toast('Please install MetaMask!')
      }
    }
    calculateSteps()
    asyncSwitchNetwork()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bridgeFrom, bridgeTo])


  const handleSelectBridgeFrom = (selectedItem: BridgeLocation) => {
    const selectedLocation = Object.entries(BridgeLocation).find(([key]) => key === selectedItem)?.[1]
    if (selectedLocation) {
      setBridgeFrom(selectedLocation)
      dispatch(setChainFromStatus(selectedLocation))
    }
  }

  const handleSelectBridgeTo = (selectedItem: BridgeLocation) => {
    const selectedLocation = Object.entries(BridgeLocation).find(([key]) => key === selectedItem)?.[1]
    if (selectedLocation) {
      setBridgeTo(selectedLocation)
      dispatch(setChainToStatus(selectedLocation))
    }
  }

  const handleSwitchBrige = () => {
    const temp = bridgeFrom
    setBridgeFrom(bridgeTo)
    dispatch(setChainFromStatus(bridgeTo))
    setBridgeTo(temp)
    dispatch(setChainToStatus(bridgeFrom))
  }

  return (
    <div className="relative flex xs:mt-24" style={{ zIndex: 45, minHeight: '151px' }}>
      {/* <div className="bg absolute w-full h-full transition-all duration-300 z-[-1]" /> */}
      <div className="flex-auto hero">
        <div className="relative flex flex-col shadow-lg hero-content rounded-xl">
          <div className="flex justify-center">
            <div className="mr-4">
              <BridgeSelector hideOption={bridgeTo} defaultLocation={bridgeFrom} onSelect={handleSelectBridgeFrom} />
            </div>
            <div className="flex items-end p-2" style={{ cursor: 'pointer' }}>
              <ArrowRight onClick={handleSwitchBrige} />
            </div>
            <div className="ml-4">
              <BridgeSelector hideOption={bridgeFrom} defaultLocation={bridgeTo} onSelect={handleSelectBridgeTo} />
            </div>
          </div>
          {BridgeLocation.None === bridgeFrom ? 
            <Message>
              <Image 
                src="/images/warning.svg"
                width={20}
                height={20}
                alt="warning icon"
              />
              <span>Select source and destination to begin</span>
            </Message> 
            : 
            <BridgeSteps bridgeSteps={bridgeSteps} />}
        </div>
      </div>
    </div>
  )
}

const Message = styled.div`
  img {
    display:inline-block;
    margin-right:5px;
  }
`
export default Swap
