import { styled } from 'styled-components'
import ConfirmDPS from './components/ConfirmDPS'
import ConfirmNative from './components/ConfirmNative'
import ConfirmResource from './components/ConfirmResource'
import ConfirmShips from './components/ConfirmShips'
import Image from 'next/image'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import {
  setDPSBalance,
  setNativeBalance,
  setConfirmDPS,
  setConfirmNative,
  reSetConfirmData,
  setModalFlag,
} from '@/features/bridge/bridgeSlice'

const ConfirmArea = () => {
  const dispatch = useAppDispatch()

  const {
    dpsBalance,
    nativeBalance,
    confirmedShips,
    confirmResource,
    confirmBalance,
    confirmNative,
    isConfirm
  } = useAppSelector((state: any) => state.bridge)

  const onReset = () => {
    dispatch(setDPSBalance(dpsBalance + confirmBalance))
    dispatch(setNativeBalance(nativeBalance + confirmNative))
    dispatch(setConfirmNative('Confirm'))
    dispatch(setConfirmDPS('Confirm DPS'))
    dispatch(reSetConfirmData({ confirmedShips: confirmedShips, confirmResources: confirmResource }))
  }

  return (
    <div className="right-content">
      <ConfirmNative />
      <ConfirmDPS />
      <ConfirmResource />
      <div className="confirm-ships">
        <AmountTitle>Ships</AmountTitle>
        <ConfirmShips />
      </div>
      <div className="confirm-btngroup">
        <TrandeBtn>
          <Image src="/asset/bridge/confirm_btn.png" alt="confirm" width={100} height={30} />
          <div
            className="confirm-btn"
            onClick={() => {
              if (isConfirm) dispatch(setModalFlag(true))
              else window.alert("Please add values")
            }}
          >
            Confirm
          </div>
        </TrandeBtn>
        <TrandeBtn onClick={onReset}>
          <Image src="/asset/bridge/reset_btn.png" alt="reset" width={100} height={30} />
          <div className="reset-btn">Reset</div>
        </TrandeBtn>
      </div>
    </div>
  )
}

export default ConfirmArea

const AmountTitle = styled.span`
  font-size: 18px;
`

const TrandeBtn = styled.div`
  cursor: pointer;
  & {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    img {
      opacity: 0.85;
    }
  }
  & > div {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
`
