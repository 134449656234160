import { useEffect } from "react"
import { styled } from "styled-components"
import AvailableDPS from "./components/AvailableDPS"
import AvailableNative from "./components/AvailableNative"
import AvailableResource from "./components/AvailableResource"
import AvailableShips from "./components/AvailableShips"
import useSWR from "swr"
import { useAppDispatch, useAppSelector } from "src/redux/hook"
import { setAvailableShips, resetConfirmShip } from "@/features/bridge/bridgeSlice"
import { FetchBridgeOutData, FetchBridgeInData } from "@/utils/apiFetching"
import { BridgeLocation } from "@/entities/bridgeLocation"
import { useAccount } from "wagmi"


const SelectionArea = () => {
  const dispatch = useAppDispatch()
  const { address } = useAccount()
  const {
    chainFromStatus,
    chainToStatus,
  } = useAppSelector((state: any) => state.bridge)

  const { data: bridgedInShipsEth } = useSWR([`BridgeIn/eth`, address], () =>
    FetchBridgeInData(BridgeLocation.ETH, address as string)
  )

  const { data: bridgedInShipsBsc } = useSWR([`BridgeIn/bsc`, address], () =>
    FetchBridgeInData(BridgeLocation.BSC, address as string)
  )

  const { data: bridgeOutAssets } = useSWR([`BridgeOut`, chainToStatus, address], () =>
    FetchBridgeOutData(chainToStatus as BridgeLocation, address as string)
  )

  useEffect(() => {
    if (chainFromStatus === BridgeLocation.Game) {
      if (bridgeOutAssets) {
        const shipInfos = bridgeOutAssets?.bridgableItems?.shipDetails.map(ship => ({
          ...ship,
          textureNum: ship.textureNum.toString(), // Convert number to string if required
        }));
        dispatch(setAvailableShips(shipInfos));
      }
    } else if (chainFromStatus === BridgeLocation.BSC) {
      if (bridgedInShipsBsc) {
        dispatch(setAvailableShips(bridgedInShipsBsc))
      }
    } else if (chainFromStatus === BridgeLocation.ETH) {
      if (bridgedInShipsEth) {
        dispatch(setAvailableShips(bridgedInShipsEth))
      }
    }
    dispatch(resetConfirmShip())
  }, [chainFromStatus, bridgedInShipsEth, bridgedInShipsBsc, bridgeOutAssets, dispatch])


  return (
    <div className="left-content">
      <AvailableNative />
      <AvailableDPS />
      <AvailableResource />
      <div className="ships">
        <AmountTitle>Available Ships</AmountTitle>
        <AvailableShips />
      </div>
    </div>
  )
}

export default SelectionArea

const AmountTitle = styled.span`
  font-size: 18px;
`