import React from 'react'
import Image from 'next/image'
import { getCoreImageUrl, getNFTImageURL, getStarCount } from '@/utils/deepspace'
import StarRatingComponent from 'react-star-rating-component'
import { SHIP_TYPES } from '@/constants/assets'
import cn from 'classnames'
import { useAppDispatch } from '../../../redux/hook'
import { setConfirmShip } from '@/features/bridge/bridgeSlice'

const ShipItem = (props:any) => {
  const dispatch = useAppDispatch()
  const {item} = props
  const selectShip = (singleShip: any) => {
    dispatch(setConfirmShip(singleShip))
  }
  const stars = getStarCount(item.stats)
  const nftImageURL = getNFTImageURL(item.shipType, item.textureType, item.textureNum)
  const shipClass = SHIP_TYPES[item.shipType]
  const coreTypeImage = getCoreImageUrl(item.coreType)
return (
    <div className={cn('cursor-pointer')} onClick={() => selectShip(item)}>
      <div className="relative ship-item">
        <div className={cn('ship-img')}>
          <Image src={nftImageURL} width={85} height={85} alt="shipImage" />
        </div>
        <div className="ship-detail">
          <div>{shipClass}</div>
          <div className="choose-ship-rating">
            <StarRatingComponent
              name="shipRating"
              starCount={5}
              value={stars}
              emptyStarColor={'#D1D5DB'}
            />
          </div>
          <div style={{ fontSize: '10px' }}>SHIP-ID: {item.tokenId}</div>
        </div>
        <div className="absolute core-type-section">
          <Image src={coreTypeImage} width={15} height={15} alt="Core Type Image" />
        </div>
      </div>
    </div>
  )
}

export default ShipItem
