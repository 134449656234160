import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import Web3Token from 'web3-token'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNetwork, useAccount, useSwitchNetwork } from 'wagmi'
import useSWR from 'swr'
import LogRocket from 'logrocket'

import { Config } from '@/config/config'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { setModalFlag, setIsConfirm, switchChainFromToStatus, addLog, resetLog } from '@/features/bridge/bridgeSlice'
import { FetchBridgeOutData } from "@/utils/apiFetching"
import { BridgeLocation } from '@/entities/bridgeLocation'
import { ShipInfo } from '@/entities/dto/ship'
import { ethers, BigNumber } from 'ethers'
import { notifyError, useBridgeInBsc, useBridgeInEth, useBridgeOutBsc, useBridgeOutEth } from '@/hooks/useBridge'
import { getEthersSigner } from '@/utils/ethers'
import SelectionArea from './SelectionArea'
import ConfirmArea from './ConfirmArea'
import BridgeConfirmModal from './components/BridgeConfirmModal'
import LoadingSpinner from './components/LoadingSpinner'
import BscHook from './components/BscHook'

function sleep(ms:number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
const checkForBridgeOut: any = async (params: any) => {
  try {
    const availableItems = await axios.get(`${Config.backendUrl()}/api/BridgeOut/${params.chain}/${params.address}`)
    if (availableItems.data.success) {
      const availableDps = BigNumber.from(availableItems.data.bridgableItems.dpsBalance)
      const paramDps = BigNumber.from(params.data.dpsBalance)
      if (
        (availableDps.gte(BigNumber.from(paramDps))) &&
        params.data.shipIds.every((item: any) => availableItems.data.bridgableItems.shipIds.includes(item))
      ) {
        return { status: true, message: '' }
      } 
    }
    if (availableItems?.data?.errorCode === 4) {
      return { status: false, message: 'please return to hangar' }
    } /*else if (availableItems?.data?.errorCode === 3) {
      return { status:false, message:  'Bridge-out is unavailable while another bridge-out is still processing' }
    }*/
    const current= performance.now()
    if (current - params.startTime < 140_000) {
      await sleep(200)
      return await checkForBridgeOut(params)
    } else {
      return { status: false, message: 'dpsBalance and shipIds are invalid, please try again' }
    }
    
  } catch (error) {
    return { status: false, messsage: error }
  }
}
const repeatBridgeOut: any = async (params:any) => {
  const {chain, bridgeItems, web3Token, address} = params
  const startTime = performance.now()
  const { status, message: errorMessage } = (await checkForBridgeOut({ chain, address, data: bridgeItems, startTime }))
  if (status) {
    const apiResponse = await axios.post(`${Config.backendUrl()}/api/BridgeOut/${chain}`, {
      bridgeItems,
      web3token: web3Token,
    })
    if (apiResponse.data.success === false) {
      switch (apiResponse?.data?.errorCode) {
        case 3:
        case 6:
          return await repeatBridgeOut({chain, address, bridgeItems, web3Token})
        case 4:
          break
        default:
       }
    }
    return apiResponse
  } else {
    console.log(errorMessage)
    return {
      data : {
        success: false,
        errorCode: 10
      }
    }
  }
}
function useBridgeMergedHook() {
  const [loadSpinner, setLoadSpinner] = useState(0)
  const [signer, setSigner] = useState<any>()

  const { chain } = useNetwork()

  const { write: bridgeInEth, writeAsync: bridgeInEthAsync } = useBridgeInEth(setLoadSpinner)
  const { write: bridgeOutEth, writeAsync: bridgeOutEthAsync } = useBridgeOutEth(setLoadSpinner)
  const { write: bridgeInBsc, writeAsync: bridgeInBscAsync } = useBridgeInBsc(setLoadSpinner)
  const { write: bridgeOutBsc, writeAsync: bridgeOutBscAsync } = useBridgeOutBsc(setLoadSpinner)

  useEffect(() => {
    const setSignerFun = async (chainId: number, setSigner: any) => {
      const _signer = await getEthersSigner({ chainId })
      setSigner(_signer)
    }    
    if (chain) {
      setSignerFun(chain.id, setSigner)
    }
  }, [chain])

  return {
    loadSpinner,
    setLoadSpinner,
    signer,
    bridgeInEth,
    bridgeInEthAsync,
    bridgeOutEth,
    bridgeOutEthAsync,
    bridgeInBsc,
    bridgeInBscAsync,
    bridgeOutBsc,
    bridgeOutBscAsync,
  }
}
const BridgeArea = () => {
  const dispatch = useAppDispatch()

  const { chainFromStatus, chainToStatus, modalFlag, confirmedShips, confirmResource, confirmBalance, confirmNative } =
    useAppSelector((state: any) => state.bridge)

  const { address } = useAccount()

  const { switchNetworkAsync } = useSwitchNetwork()

  const {
    loadSpinner,
    setLoadSpinner,
    signer,
    bridgeInEth,
    bridgeInEthAsync,
    bridgeOutEth,
    bridgeOutEthAsync,
    bridgeOutBsc,
    bridgeOutBscAsync,
    bridgeInBsc,
    bridgeInBscAsync,
  } = useBridgeMergedHook()
  const [interval, setInterval] = useState(0)
  const [outProcess, setOutProcess] = useState(false)
  const [hookDisable, setHookDisable] = useState(false) // disable BscHook component
  const { data: bridgeOutAssets } = useSWR([`BridgeOut`, chainToStatus, address], () =>
    FetchBridgeOutData(chainToStatus as BridgeLocation, address as string),
    { refreshInterval: interval },
  )
  useEffect(() => {
    if (chainFromStatus === BridgeLocation.Game && outProcess) {
      if (bridgeOutAssets?.errorCode === 3) {
        setLoadSpinner(1)
      } if (bridgeOutAssets?.errorCode === null) {
        setInterval(0)
        setLoadSpinner(2)
        setOutProcess(false)
        setTimeout(() => {
          setLoadSpinner(0)
        }, 3000)
      }
    }
  }, [bridgeOutAssets?.errorCode, loadSpinner])

  useEffect(() => {
    const shipIds =
      confirmedShips?.length > 0
        ? confirmedShips.map((shipData: ShipInfo) => {
          const shipId = Number(shipData?.tokenId)
          return shipId
        })
        : []
    const resourceIds = confirmResource?.length > 0 ? confirmResource.map((resource: any) => resource?.pid) : []
    const dpsAmount = ethers.utils.parseUnits(confirmBalance === 'Confirm DPS' ? '0' : confirmBalance, 9).toString()
    const nativeAmount = ethers.utils.parseUnits(confirmNative === 'Confirm' ? '0' : confirmNative, 18).toString()
    if (chainToStatus === BridgeLocation.Game) {
      if (chainFromStatus === BridgeLocation.ETH) {
        if (shipIds.length === 0 && parseInt(dpsAmount) === 0 && parseInt(nativeAmount) === 0) {
          dispatch(setIsConfirm(false))
        } else dispatch(setIsConfirm(true))
      } else if (chainFromStatus === BridgeLocation.BSC) {
        if (shipIds.length === 0 && resourceIds.length === 0 && parseInt(dpsAmount) === 0 && parseInt(nativeAmount) === 0) {
          dispatch(setIsConfirm(false))
        } else dispatch(setIsConfirm(true))
      }
    } else {
      if (chainFromStatus === BridgeLocation.Game) {
        if (chainToStatus === BridgeLocation.ETH) {
          if (shipIds.length === 0 && parseInt(dpsAmount) === 0 && parseInt(nativeAmount) === 0) {
            dispatch(setIsConfirm(false))
          } else dispatch(setIsConfirm(true))
        } else if (chainToStatus === BridgeLocation.BSC) {
          if (shipIds.length === 0 && parseInt(dpsAmount) === 0 && parseInt(nativeAmount) === 0 && resourceIds.length === 0) {
            dispatch(setIsConfirm(false))
          } else dispatch(setIsConfirm(true))
        }
      } else {
        if (chainFromStatus === BridgeLocation.ETH && chainToStatus === BridgeLocation.BSC) {
          if (shipIds.length === 0 && parseInt(dpsAmount) === 0) {
            dispatch(setIsConfirm(false))
          } else dispatch(setIsConfirm(true))
        } else if (chainFromStatus === BridgeLocation.BSC && chainToStatus === BridgeLocation.ETH) {
          if (shipIds.length === 0 && parseInt(dpsAmount) === 0) {
            dispatch(setIsConfirm(false))
          } else dispatch(setIsConfirm(true))
        }
      }
    }
  }, [confirmedShips, confirmResource, confirmBalance, confirmNative])
  useEffect(() => {
    setHookDisable(false)
    if (address)LogRocket.identify(address)
  }, [address])
  const handleSetConfirmed = async () => {
    const bridgeOut = async (bridgeItems: any, chain: string, out: any, outItems: any, setLoadSpinner: any, _signerObject:any) => {
      console.log(_signerObject, address)
      let i = 0
      console.log(_signerObject)
      while (_signerObject === undefined)  {
        if (chainObject) {
          _signerObject = await getEthersSigner({ chainId: chainObject.id })
        }
        await sleep(500)
        i++;
        console.log(_signerObject, i)
      } 
      if (_signerObject && address) {
        let web3Token
        try {
          web3Token = await Web3Token.sign((body: any) => _signerObject.signMessage(body), {
            statement: 'Click "Sign" to begin the bridge out process',
            domain: 'deepspace.game',
            expires_in: '30m',
            request_id: ethers.utils.id(JSON.stringify(bridgeItems)),
          })

        } catch (error) {
          setLoadSpinner(3)
          notifyError("Failed to sign to initiate the bridge out process.", error, 1)
          return
        }
        try {
          const apiResponse = await axios.post(`${Config.backendUrl()}/api/BridgeOut/${chain}`, {
            bridgeItems: bridgeItems,
            web3token: web3Token,
          })
          setInterval(2000)
          if (apiResponse.data.success) {
            out({
              args: [
                apiResponse.data.signature,
                apiResponse.data.deadline,
                address,
                ...outItems,
              ],
            })
          } else {
            const error = apiResponse.data.errorMessage
            switch (apiResponse?.data?.errorCode) {
              case 3:
                notifyError("", "Bridge-out is unavailable while another bridge-out is still processing", 2)
                break
              case 4:
                notifyError("", "please return to hangar", 3)
                break
              default:
                notifyError(`Failed to post with bridge out api from ${chainFromStatus} to ${chainToStatus}.`, error, 4)
            }
            setLoadSpinner(3)
          }
        } catch (error) {
          setLoadSpinner(3)
          notifyError(`Failed to bridge out from ${chainFromStatus} to ${chainToStatus}.`, error, 5)
        }
      }
    }
    const swap = async (
      bridgeItems: any,
      bridgeInItems: any,
      bridgeOutItems: any,
      chain: string,
      switchNetworkAsync: any,
      inAsync: any,
      outAync: any,
      setLoadSpinner: any,
      signerObject: any
    ) => {
      let isChecked = false
      let _signerObject = signerObject
      try {
        await inAsync({ args: bridgeInItems })
      } catch (error) {
        setLoadSpinner(3)
        isChecked = false
        notifyError(`Failed to bridge in from ${chainFromStatus} to Game.`, error, 6)
        return
      }
      try {
        const startTime = performance.now()
        const { status, message: errorMessage } = (await checkForBridgeOut({ chain, address, data: bridgeItems, startTime }))
        isChecked = status
        if (isChecked === false) {
          if (errorMessage !== "") {
            notifyError("", errorMessage, 7)
          }
        }
      } catch (error) {
        setLoadSpinner(3)
        isChecked = false
        notifyError(`Failed to check the possibility to bridge out Game to ${chainToStatus}.`, error, 8)
      }

      if (isChecked) {
        try {
          if (chain === 'bsc') {
            await switchNetworkAsync(Config.chainIdBsc())
          }
          else {
            await switchNetworkAsync(Config.chainIdEth())
          }
        } catch (error) {
          setLoadSpinner(3)
          notifyError(`Failed to switch from ${chainFromStatus} to ${chainToStatus}.`, error, 9)
          isChecked = false
        }
        let i = 0
        console.log(_signerObject)
        while (_signerObject === undefined)  {
          if (chainObject) {
            _signerObject = await getEthersSigner({ chainId: chainObject.id })
          }
          await sleep(500)
          i++;
          console.log(_signerObject, i)
        } 
        if (_signerObject && address && isChecked) {
          let apiResponse
          let web3Token
          try {
            web3Token = await Web3Token.sign((body: any) => _signerObject.signMessage(body), {
              statement: 'Click "Sign" to begin the bridge out process',
              domain: 'deepspace.game',
              expires_in: '30m',
              request_id: ethers.utils.id(JSON.stringify(bridgeItems)),
            })
          } catch (error) {
            setLoadSpinner(3)
            notifyError("Failed to sign to initiate the bridge in out process.", error, 10)
            return
          }
          try {
            apiResponse = await repeatBridgeOut({chain, address, bridgeItems, web3Token})
            setInterval(2000)
            if (apiResponse.data.success) {
              const tx = await outAync({
                args: [
                  apiResponse.data.signature,
                  apiResponse.data.deadline,
                  address,
                  ...bridgeOutItems
                ],
              })
              if (tx.hash) {
                setLoadSpinner(2)
                setTimeout(() => {
                  setLoadSpinner(0)
                }, 2000)
                dispatch(switchChainFromToStatus())
              } else {
                notifyError("", "Transaction failed", 15)
                setLoadSpinner(3)
              }
            } else {
              const error = apiResponse.data.errorMessage
              switch (apiResponse?.data?.errorCode) {
                case 3:
                  notifyError("", "Bridge-out is unavailable while another bridge-out is still processing", 11)
                  break
                case 4:
                  notifyError("", "please return to hangar", 12)
                  break
                case 6:
                default:
                  notifyError(`Failed to post with bridge out api from ${chainFromStatus} to Game.`, error, 13)
                }
                setLoadSpinner(3)
                }
          } catch (error) {
            setLoadSpinner(3)
            notifyError(`Failed to bridge out from ${chainFromStatus} to Game.`, error, 14)
          }
        } else {
          setLoadSpinner(3)
          notifyError(`Failed something from ${chainFromStatus} to ${chainToStatus}.`, '', 16)
          console.log(_signerObject, address, isChecked)
        }
      } else {
        notifyError(`Failed something from ${chainFromStatus} to ${chainToStatus}.`, '', 17)
        setLoadSpinner(3)
      }
    }
    dispatch(addLog({title:"start",address,chainFromStatus, chainToStatus}))
    dispatch(setModalFlag(false))
    setLoadSpinner(1)
    //Ships and resources
    const shipIds =
      confirmedShips?.length > 0
        ? confirmedShips.map((shipData: ShipInfo) => {
          const shipId = Number(shipData?.tokenId)
          return shipId
        })
        : []
    const resourceIds = confirmResource?.length > 0 ? confirmResource.map((resource: any) => resource?.pid) : []
    const resourceAmounts =
      confirmResource?.length > 0 ? confirmResource.map((resource: any) => resource?.quantity) : []
    const dpsAmount = ethers.utils.parseUnits(confirmBalance === 'Confirm DPS' ? '0' : confirmBalance, 9).toString()
    const nativeAmount = ethers.utils.parseUnits(confirmNative === 'Confirm' ? '0' : confirmNative, 18).toString()
    setHookDisable(true)
    if (chainToStatus === BridgeLocation.Game) {
      if (chainFromStatus === BridgeLocation.ETH) {
        dispatch(addLog({title:"bridgeInEth",params:{shipIds, dpsAmount, nativeAmount}}))
        bridgeInEth({ args: [shipIds, dpsAmount], value: BigInt(nativeAmount) })
      } else if (chainFromStatus === BridgeLocation.BSC) {
        dispatch(addLog({title:"bridgeInBsc",params:{shipIds, resourceIds, resourceAmounts, dpsAmount, nativeAmount}}))
        bridgeInBsc({ args: [shipIds, resourceIds, resourceAmounts, dpsAmount], value: BigInt(nativeAmount) })
      }
    } else {
      if (chainFromStatus === BridgeLocation.Game) {
        if (chainToStatus === BridgeLocation.ETH) {
          const bridgeItems = {
            dpsBalance: dpsAmount,
            nativeTokenBalance: nativeAmount,
            shipIds: shipIds,
          }
          await bridgeOut(bridgeItems, 'eth', bridgeOutEth, [shipIds, dpsAmount, nativeAmount], setLoadSpinner, signer)
        } else if (chainToStatus === BridgeLocation.BSC) {
          const resources = resourceIds.map((pid: any, index: any) => {
            return { resourceId: pid, balance: resourceAmounts[index] }
          })
          const bridgeItems = {
            dpsBalance: dpsAmount,
            nativeTokenBalance: nativeAmount,
            resources: resources,
            shipIds: shipIds,
          }
          await bridgeOut(bridgeItems, 'bsc', bridgeOutBsc, [shipIds, resourceIds, resourceAmounts, dpsAmount, nativeAmount], setLoadSpinner, signer)
        }
        setOutProcess(true)
      } else {
        if (chainFromStatus === BridgeLocation.ETH && chainToStatus === BridgeLocation.BSC && switchNetworkAsync) {
          const bridgeData = {
            shipIds: shipIds,
            dpsBalance: dpsAmount,
            resources: [],
            nativeTokenBalance: 0,
          }
          const bridgeInData = [bridgeData.shipIds, bridgeData.dpsBalance]
          const bridgeOutData = [
            bridgeData.shipIds,
            [],
            [],
            bridgeData.dpsBalance,
            bridgeData.nativeTokenBalance
          ]
          await swap(
            bridgeData,
            bridgeInData,
            bridgeOutData,
            'bsc',
            switchNetworkAsync,
            bridgeInEthAsync,
            bridgeOutBscAsync,
            setLoadSpinner,
            signer
          )
          setOutProcess(true)
        } else if (
          chainFromStatus === BridgeLocation.BSC &&
          chainToStatus === BridgeLocation.ETH &&
          switchNetworkAsync
        ) {
          const bridgeData = {
            shipIds: shipIds,
            dpsBalance: dpsAmount,
            nativeTokenBalance: 0,
          }
          const bridgeInData = [bridgeData.shipIds, [], [], bridgeData.dpsBalance]
          const bridgeOutData = [
            bridgeData.shipIds,
            bridgeData.dpsBalance,
            bridgeData.nativeTokenBalance
          ]
          await swap(
            bridgeData,
            bridgeInData,
            bridgeOutData,
            'eth',
            switchNetworkAsync,
            bridgeInBscAsync,
            bridgeOutEthAsync,
            setLoadSpinner,
            signer
          )
          setOutProcess(true)
        }
      }
    }
  }
  const [isBridgeInResourcesApproved, setIsBridgeInApproved] = useState(chainFromStatus !== BridgeLocation.BSC)
  const [approvedClick, setApprovedClick] = useState(false)

  useEffect(() => {
    if (chainFromStatus !== BridgeLocation.BSC) setIsBridgeInApproved(true)
  }, [chainFromStatus])

  const { chain:chainObject } = useNetwork()

  const handleLoading = (status: number) => {
    setLoadSpinner(status)
    if (status === 3)notifyError(`Failed something from ${chainFromStatus} to ${chainToStatus}.`, '', 17)
  }
  useEffect(()=> {
    if (loadSpinner === 3)console.warn('failed tranactions')
  }, [loadSpinner])
  useEffect(() => {
    dispatch(resetLog())
    setHookDisable(false)
  }, [chainFromStatus, chainToStatus])
  return (
    <BridgeStyle className="block w-full">
      <div className="bridge-container goldman-font">
        <BridgeWrapper>
          <BackGroundWapper>
            <ComImage>
              <Image src="/asset/bridge/base_bridge.png" width={1100} height={1024} alt="back" />
            </ComImage>
            <MobileImage />
            <div className="bridge-left">
              <Title>Selection Area</Title>
              <SelectionArea />
            </div>
            <div className="bridge-right">
              <Title>Confirm Area</Title>
              <ConfirmArea />
            </div>
          </BackGroundWapper>
        </BridgeWrapper>
        <ToastContainer />
      </div>
      {
        address &&
        chainFromStatus === BridgeLocation.BSC &&
        chainObject?.id !==undefined && 
        typeof chainObject?.id ===  'number' &&
        [56, 97].includes(chainObject?.id) && 
        hookDisable === false &&
        <BscHook 
          setIsBridgeInApproved={setIsBridgeInApproved} 
          approvedClick={approvedClick}
          setApprovedClick={setApprovedClick}
          setLoadSpinner={setLoadSpinner} 
        />
      }
      {
        address && 
        modalFlag === true && 
        <BridgeConfirmModal 
          handleSetConfirmed={handleSetConfirmed} 
          setApprovedClick={setApprovedClick} 
          isBridgeInApproved={isBridgeInResourcesApproved} 
        />
      }
      {loadSpinner ? <LoadingSpinner status={loadSpinner} handleLoading={handleLoading} /> : ''}
    </BridgeStyle>
  )
}

export default BridgeArea

const BridgeStyle = styled.div`

  @media(max-device-width:390px) {
    display: flex;
    justify-content: center;
  }

  .bridge-container 
    justify-content: center;
   
  }

  .bridge-left {
    position: absolute;
    top: 0px;
    width: 483px;
    height: 554px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 452px;
    margin-top: 57px;
    .left-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 20px;
      .available-dps {
        display: flex;
        align-items: center;

      }
      .resource {
       margin-top:10px
      } 
      .ships {
        margin-top:5px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .available-ships {
          flex-grow: 1;
        }
      }
    }
  }
  .bridge-right {
    position: absolute;
    margin-left: 583px;
    height: 554px;
    display: flex;
    flex-direction: column;
    top: 57px;
    .right-content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 365px;
      .confirm-resource {
        margin-top: 5px;
      }
      .confirm-ships {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
      .confirm-btngroup {
        margin-top: 10px;
        display: flex;
        justify-content: space-around;
      }
    }
  }
`
const BridgeWrapper = styled.div`
  position: relative;
  @media (max-device-width: 400px) {
    margin-bottom: -145px;
  }
`
const BackGroundWapper = styled.div`
  min-width: 1102px !important;
  min-height: 664px !important;
  display: flex;
  justify-content: center;

  @media (max-width: 1030px) {
    & {
      min-width: 100% !important;
      min-height: 1024px !important;
      padding-bottom: 35px;
      .arrow-img {
        display: contents;
      }
      .bridge-left {
        margin-right: 0 !important;
        height: 430px;
        width: 392px;
        .tab-container > div {
          margin-top: 20px;
          & > div {
            width: 75%;
            height: 30px;
          }
        }
        .left-content {
          padding-top: 10px !important;
          & > span {
            // margin-left: 57px;
            font-size: 14px;
          }
          .available-dps {
            display: flex;
            justify-content: center;

            & > div:first-child {
              width: 130px;
              & > div {
                width: 128px;
              }
            }
            & > div:last-child {
              width: 140px;
              & > button {
                width: 30px;
                font-size: 12px;
                position: relative;
                left: -23px;
                height: 17px;
              }
            }
          }
          .resource {
            & > span {
              // margin-left: 57px;
              font-size: 14px;
            }
            .available-resource > div {
              margin: 0 auto;
              // width: 75%;
              height: 50px;
              & > div {
                height: 41px;
                padding: 0px 2px;
                & > div {
                  width: 36px;
                  height: 36px;
                }
              }
            }
          }
        }
        .ships {
          margin-top: 0px !important;
          & > span {
            // margin-left: 57px;
            font-size: 14px;
          }
          .available-ships > div {
            margin: 0 auto;
            // width: 75%;
            .choose-ship.goldman-font {
              padding: 5px;
              padding-top: 7px;
              height: 100% !important;
              .ship-search {
                .search-icon {
                  top: 5px;
                }
                .serch-input {
                  height: 25px;
                }
              }
            }
          }
          .ships-container {
            .cursor-pointer {
              width: 62px;
              .ship-img {
                width: 60px;
                height: 58px;
              }
            }
          }
        }
      }
      .bridge-right {
        margin-left: 0 !important;
        height: 430px;
        top: 558px;
        .right-content {
          padding-top: 0;
          margin-top: 20px;
          .confirm-resource {
            margin-top: 0px;
            & > span {
              font-size: 14px;
            }
          }
          .confirm-ships {
            margin-top: 0px;
            & > span {
              font-size: 14px;
            }
            & > div {
              .choose-ship.goldman-font {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 360px) {
    & {
      .bridge-left {
        width: 320px;
        .left-content {
          .available-dps {
            & > div:first-child {
              width: 113px;
              & > div {
                width: 112px;
              }
            }
            & > div:last-child {
              width: 140px;
              & > button {
                width: 30px;
                font-size: 12px;
                position: relative;
                left: -23px;
                height: 17px;
              }
            }
          }
        }
      }
      .bridge-right {
        .right-content {
          width:349px;
        }
      }
    }
  }

`
const Title = styled.div`
  text-align: center;
  font-size: 18px;
`
const ComImage = styled.div`
  & {
    @media (max-device-width: 1030px) {
      display: none;
    }
  }
  & {
    @media (max-width: 1030px) {
      display: none;
    }
  }
`
const MobileImage = styled.div`
  display: none;
  width: 478px;
  height: 1040px;
  background-image: url('/images/bridge_mobile_bg.png');
  background-repeat: no-repeat;
  background-position: center, center;
  & {
    @media (max-device-width: 478px) {
      display: block;
      width: 375px;
    }
    @media (max-device-width: 360px) {
      display: block;
      width: 320px;
    }
  }
  & {
    @media (max-width: 1030px) {
      display: block;
    }
  }
`
