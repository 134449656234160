import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../redux/store'
import { ShipInfo } from '@/entities/dto/ship'

// Define a type for the slice state
interface BridgeState {
  chainFromStatus: string
  chainToStatus: string
  dpsBalance: number | null
  confirmBalance: string
  nativeBalance: number
  confirmNative: string
  resourceBalance: Array<any>
  confirmResource: any
  availableShips: ShipInfo[]
  confirmedShips: ShipInfo[]
  modalFlag: boolean
  confirmFlag: boolean
  isConfirm: boolean
  switchFlag: boolean
  loadSpinner:number
  chainChangeStatus:boolean
  logs: Array<any>
}

// Define the initial state using that type
const initialState: BridgeState = {
  confirmResource: [],
  chainFromStatus: '',
  chainToStatus: '',
  dpsBalance: 0,
  confirmBalance: 'Confirm DPS',
  confirmNative: 'Confirm',
  nativeBalance: 0,
  resourceBalance: [],
  availableShips: [] as ShipInfo[],
  confirmedShips: [] as ShipInfo[],
  modalFlag: false,
  confirmFlag:false,
  isConfirm:false,
  switchFlag:false,
  loadSpinner:0,
  chainChangeStatus:false,
  logs:[]
}

export const bridgeSlice = createSlice({
  name: 'bridge',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setChainFromStatus: (state, action: PayloadAction<string>) => {
      const data = action.payload
      state.chainChangeStatus = false
      state.chainFromStatus = data
    },
    setChainToStatus: (state, action: PayloadAction<string>) => {
      const data = action.payload
      state.chainChangeStatus = false
      state.chainToStatus = data
    },
    switchChainFromToStatus: (state) => {
      const tempFrom = state.chainFromStatus
      const tempTo = state.chainToStatus  
      state.chainChangeStatus = true
      state.chainToStatus = tempFrom
      state.chainFromStatus = tempTo
    },
    setLoadSpinner: (state,{payload}) => {
      state.loadSpinner = payload
    },
    setModalFlag: (state,{payload}) => {
      state.modalFlag = payload
    },
    setConfirmFlag: (state,{payload}) => {
      state.chainChangeStatus = false
      state.confirmFlag = payload
    },
    setIsConfirm: (state,{payload}) => {
      state.isConfirm = payload
    },
    setSwitchFlag: (state,{payload}) => {
      state.switchFlag = payload
    },
    setDPSBalance: (state, action: PayloadAction<number>) => {
      const data = action.payload
      state.dpsBalance = data
    },
    setConfirmDPS: (state, { payload }) => {
      state.confirmBalance = payload
    },
    setNativeBalance: (state, action: PayloadAction<number>) => {
      const data = action.payload
      state.nativeBalance = data
    },
    setConfirmNative: (state, { payload }) => {
      state.confirmNative = payload
    },
    setResourceBalance: (state, action) => {
      const data = action.payload
      state.resourceBalance = data
    },
    resetConfirmResource: (state) => {
      state.confirmResource = []
    },
    setConfirmResource: (state, { payload }) => {
      if (state.confirmResource !== undefined && state.confirmResource.length > 0) {
        const confrimedPids = state.confirmResource.map((item: any) => {
          return item.pid
        })
        if (confrimedPids.includes(payload.item.pid)) {
          state.confirmResource.map((item: any) => {
            if (item.pid === payload.item.pid) {
              item.quantity = item.quantity + payload.resouceNum
              state.resourceBalance.map((availItem) => {
                if (availItem.pid === payload.item.pid) {
                  availItem.quantity = availItem.quantity - payload.resouceNum
                }
              })
            }
          })
        } else {
          const newConfirmResourceItem = { ...payload.item, quantity: payload.resouceNum }
          state.confirmResource.push(newConfirmResourceItem)
          state.resourceBalance.map((availItem) => {
            if (availItem.pid === newConfirmResourceItem.pid) {
              availItem.quantity = availItem.quantity - payload.resouceNum
            }
          })
        }
      } else {
        const newConfirmResourceItem = { ...payload.item, quantity: payload.resouceNum }
        state.confirmResource.push(newConfirmResourceItem)
        state.resourceBalance.map((availItem) => {
          if (availItem.pid === newConfirmResourceItem.pid) {
            availItem.quantity = availItem.quantity - payload.resouceNum
          }
        })
      }
    },

    setAvailableShips: (state, action: PayloadAction<ShipInfo[]>) => {
      state.availableShips = action.payload
    },
    setConfirmShip: (state, { payload }) => {
      state.confirmedShips.push(payload)
      state.availableShips = state.availableShips.filter((item) => item.tokenId !== payload.tokenId)
    },
    resetConfirmShip: (state) => {
      state.confirmedShips = []
    },
    reSetConfirmData: (state, { payload }) => {
      state.confirmBalance = initialState.confirmBalance
      if (state.availableShips?.length > 0) {
        state.availableShips = [...state.availableShips, ...payload.confirmedShips] 
      } else {
        state.availableShips = [...payload.confirmedShips]
      }
      state.confirmedShips = []
  
      if (payload.confirmResources.length > 0) {
        payload.confirmResources.map((confirmItem: any) => {
          state.resourceBalance.map((availItem) => {
            if (confirmItem.pid === availItem.pid) {
              availItem.quantity = availItem.quantity + confirmItem.quantity
            }
          })
        })
      }
      state.confirmResource = []
    },
    addLog: (state, {payload}) => {
      state.logs = [...state.logs, payload]
    },
    resetLog: (state) => {
      state.logs = []
    }
  },
})

export const {
  setChainFromStatus,
  setChainToStatus,
  switchChainFromToStatus,
  setDPSBalance,
  setNativeBalance,
  setResourceBalance,
  setConfirmResource,
  resetConfirmResource,
  setAvailableShips,
  setConfirmShip,
  resetConfirmShip,
  setConfirmDPS,
  setConfirmNative,
  reSetConfirmData,
  setModalFlag,
  setConfirmFlag,
  setIsConfirm,
  setSwitchFlag,
  setLoadSpinner,
  addLog,
  resetLog
} = bridgeSlice.actions

// Other code such as selectors can use the imported `RootState` type

export const selectBridge = (state: RootState) => state.bridge

export default bridgeSlice.reducer
