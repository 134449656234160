import React, { useEffect, useState } from 'react'
import { MaxUint256 } from '@ethersproject/constants'
import { ethers, BigNumber } from 'ethers'

import { Config } from "@/config/config"
import { notifyError, useIsApprovedForAllBsc, useSetApprovalForAllBsc, useSetApprovalDpsBsc, useGetApprovalDpsBsc } from '@/hooks/useBridge'
import { useAppSelector } from 'src/redux/hook'
import { setModalFlag } from '@/features/bridge/bridgeSlice'


interface BscHookProps {
  setIsBridgeInApproved: React.Dispatch<React.SetStateAction<boolean>>,
  approvedClick: boolean,
  setLoadSpinner: React.Dispatch<React.SetStateAction<number>>,
  setApprovedClick: React.Dispatch<React.SetStateAction<boolean>>,
}

export default function BscHook(props: BscHookProps) {
  const { setIsBridgeInApproved, approvedClick, setLoadSpinner, setApprovedClick } = props

  const { data } = useIsApprovedForAllBsc()
  const { error: dpsError, writeAsync: approve, isSuccess: isApproveDpsSuccess } = useSetApprovalDpsBsc()
  const { error: resourcesError, writeAsync: setApprovalForAll, isSuccess: isApprovalResourceSuccess } = useSetApprovalForAllBsc()
  const { data: allowance} = useGetApprovalDpsBsc()

  const { confirmBalance, confirmResource, confirmedShips } = useAppSelector((state: any) => state.bridge)

  const allowanceDps = BigNumber.from(allowance || "0" )
  const dpsAmount = BigNumber.from(ethers.utils.parseUnits(confirmBalance === 'Confirm DPS' ? '0' : confirmBalance, 9).toString())
  //console.log(isApproveDpsSuccess, isApprovalResourceSuccess, "--success--")
  //console.log(dpsAmount.gt(BigNumber.from("0")), "--aa--")
  useEffect(() => {
    if (dpsError && dpsError.cause && typeof dpsError.cause === 'object') {
      const code = (dpsError.cause as { code?: number }).code;
      if (code === 4001) {
        // user rejected
      } else {
        notifyError("Dps failed", dpsError, 32)
      }
      setTransactionBegin(-10)
      setApprovedClick(false)
    } else {
      if (dpsError){
        notifyError("Dps failed", dpsError, 33)
        setTransactionBegin(-10)
        setApprovedClick(false)
      }
    }
  }, [dpsError])
  useEffect(() => {
    if (resourcesError && resourcesError.cause && typeof resourcesError.cause === 'object') {
      const code = (resourcesError.cause as { code?: number }).code;
      if (code === 4001) {
        // user rejected
      } else {
        notifyError("Resources failed", resourcesError, 34)
      }
      setTransactionBegin(-10)
      setApprovedClick(false)
    } else {
      if (resourcesError){
        notifyError("Resources failed", resourcesError, 35)
        setTransactionBegin(-10)
        setApprovedClick(false)
      }
    }
  }, [resourcesError])
  //console.log(dpsError)
  useEffect(() => {
    let condition = false
    if (dpsAmount.gt(BigNumber.from("0")) && confirmResource.length > 0){ // both dps and resources from bsc to game 
      if (typeof data === "boolean") {
        condition = data && allowanceDps.gte(dpsAmount)
      }
    } else if (confirmResource.length > 0) { // only resources from bsc to game 
      if (typeof data === "boolean") {
        condition = data
        console.log(condition, "--condition2--")
      }
    } else if (dpsAmount.gt(BigNumber.from("0"))) { // only dps from bsc to game or eth 
      condition = allowanceDps.gte(dpsAmount)
    } else if (dpsAmount.eq(BigNumber.from("0")) && confirmedShips.length > 0){
      condition= true
    }
    setIsBridgeInApproved(condition)
  }, [data, dpsAmount, confirmResource.length, confirmedShips])
  const [transactionBegin, setTransactionBegin] = useState(-10)
  useEffect(() => {
    if (transactionBegin> -1){
      let condition = false
      if (dpsAmount.gt(BigNumber.from("0")) && confirmResource.length > 0){
        if (typeof data === "boolean") {
          condition = data && allowanceDps.gte(dpsAmount)
        }
      } else if (confirmResource.length > 0) {
        if (typeof data === "boolean") {
          condition = data
        }
      } else if (dpsAmount.gt(BigNumber.from("0"))){
        condition = allowanceDps.gte(dpsAmount)
      }
      if (condition) {
        setLoadSpinner(0)
        setModalFlag(true)
      } else {
        setTimeout(() => {
          setTransactionBegin(transactionBegin + 1)
        }, 100)
      }
    }
  }, [transactionBegin])

  const bridgeAddress = Config.contractAddressBridgeBsc()
  useEffect(() => {
    const handleApprove = async () => {
      setLoadSpinner(1)
      try {
        setModalFlag(false)
        setLoadSpinner(1)
        if (dpsAmount.gt(BigNumber.from("0")))  await approve({ args: [bridgeAddress, MaxUint256] })
      } catch (e) {
        notifyError("Failed on approving for dps", e, 30)
        setTransactionBegin(-10)
        setLoadSpinner(3)
      }
      try {
        if (confirmResource.length > 0) await setApprovalForAll({ args: [bridgeAddress, true] })
        setTransactionBegin(0)
      } catch (e) {
        notifyError("Failed on Resources", e, 31)
        setTransactionBegin(-10)
        setLoadSpinner(3)
      }
    }
    if (approvedClick && transactionBegin < -1) handleApprove()
  }, [approvedClick, confirmResource.length])
  return null
}
