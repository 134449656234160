import { useEffect } from 'react'
import { Config } from "@/config/config"
import { useContractWrite, useContractRead, useWaitForTransaction, useAccount } from "wagmi"
import { toast } from 'react-toastify'
import LogRocket from 'logrocket'
import ABI_ETH from '@/config/abis/bridgeEth.json'
import ABI_BSC from '@/config/abis/bridgeBsc.json'
import ERC20_ABI from '@/config/abis/erc20.json'
import RESOURCES_ABI from '@/config/abis/DPS_Resources.json'
import { BridgeLocation } from '@/entities/bridgeLocation'
import { useAppSelector } from 'src/redux/hook'

export const notifyError = (message: string, error: any, index: number) => {
  if (error && typeof error === "object")console.warn(Object.keys(error))
  console.log(error?.cause?.code, index)
  console.warn(typeof error === "string" ? error : JSON.stringify(error))
  if (error?.cause?.code !== 4001) toast(message + (typeof error === "string" ? error : ""))
  if (error) {
    if (typeof error === "object") {
      LogRocket.captureException(error, {
        tags: {
          // additional data to be grouped as "tags"
          message,
          index
        }
      })  
     } else {
      LogRocket.captureMessage(error, {
        tags: {
          // additional data to be grouped as "tags"
          message,
          index
        }
      })      
     }
  }
}

export const useBridgeInEth = (setLoadSpinner: React.Dispatch<React.SetStateAction<number>>) => {
  const address = Config.contractAddressBridgeEth()
  const {
    data,
    error,
    write,
    writeAsync,
    isError,
    isSuccess,
  } = useContractWrite({
    address: address as `0x${string}`,
    abi: ABI_ETH,
    functionName: 'bridgeIn'
  })

  const { chainFromStatus, chainToStatus } = useAppSelector((state: any) => state.bridge)

  const { isSuccess: isSuccessForTransaction, isError: isErrorForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    enabled: chainFromStatus === BridgeLocation.Game || chainToStatus === BridgeLocation.Game,
  })
  useEffect(() => {
    if (chainFromStatus === BridgeLocation.ETH && chainToStatus === BridgeLocation.Game) {
      if (isSuccess) setLoadSpinner(2)
      if (isSuccessForTransaction) setLoadSpinner(0)
      if (isError) {
        notifyError(`Constract Error BridgeIn from ${chainFromStatus} to ${chainToStatus}.`, error, 20)
        setLoadSpinner(3)
      }
      if (isErrorForTransaction) {
        notifyError(`Transaction Error BridgeIn from ${chainFromStatus} to ${chainToStatus}.`, error, 21)
        setLoadSpinner(3)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessForTransaction, isSuccess, isError, isErrorForTransaction])

  return { write, writeAsync }
}

export const useBridgeOutEth = (setLoadSpinner: React.Dispatch<React.SetStateAction<number>>) => {
  const address = Config.contractAddressBridgeEth()
  const {
    data,
    error,
    write,
    writeAsync,
    isError,
    isSuccess,
  } = useContractWrite({
    address: address as `0x${string}`,
    abi: ABI_ETH,
    functionName: 'bridgeOut'
  })

  const { chainFromStatus, chainToStatus } = useAppSelector((state: any) => state.bridge)

  const { isSuccess: isSuccessForTransaction, isError: isErrorForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    enabled: chainFromStatus === BridgeLocation.Game || chainToStatus === BridgeLocation.Game,
  })

  useEffect(() => {
    if (chainFromStatus === BridgeLocation.Game && chainToStatus === BridgeLocation.ETH) {
      if (isSuccess) setLoadSpinner(2)
      if (isSuccessForTransaction) setLoadSpinner(0)
      if (isError) {
        notifyError(`Constract Error BridgeOut from ${chainFromStatus} to ${chainToStatus}.`, error, 22)
        setLoadSpinner(3)
      }
      if (isErrorForTransaction) {
        notifyError(`Transaction Error BridgeOut from ${chainFromStatus} to ${chainToStatus}.`, error, 23)
        setLoadSpinner(3)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessForTransaction, isSuccess, isError, isErrorForTransaction])

  return { write, writeAsync }
}

export const useBridgeInBsc = (setLoadSpinner: React.Dispatch<React.SetStateAction<number>>) => {
  const address = Config.contractAddressBridgeBsc()
  const {
    data,
    error,
    write,
    writeAsync,
    isError,
    isSuccess,
  } = useContractWrite({
    address: address as `0x${string}`,
    abi: ABI_BSC,
    functionName: 'bridgeIn'
  })

  const { chainFromStatus, chainToStatus } = useAppSelector((state: any) => state.bridge)

  const { data: dataForTransaction, isLoading: isLoadingForTransaction, isSuccess: isSuccessForTransaction, isError: isErrorForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    enabled: chainFromStatus === BridgeLocation.Game || chainToStatus === BridgeLocation.Game,
  })
  console.log(dataForTransaction, isLoadingForTransaction, isSuccessForTransaction, 'bridgeInBsc')
  useEffect(() => {
    if (chainFromStatus === BridgeLocation.BSC && chainToStatus === BridgeLocation.Game) {
      if (isSuccess) setLoadSpinner(2)
      if (isSuccessForTransaction) setLoadSpinner(0)
      if (isError) {
        notifyError(`Constract Error BridgeIn from ${chainFromStatus} to ${chainToStatus}.`, error, 24)
        setLoadSpinner(3)
      }
      if (isErrorForTransaction) {
        notifyError(`Transaction Error BridgeIn from ${chainFromStatus} to ${chainToStatus}.`, error, 25)
        setLoadSpinner(3)
      }
    }

    if (chainFromStatus === BridgeLocation.BSC && chainToStatus === BridgeLocation.ETH) {
      if (isError || isErrorForTransaction) setLoadSpinner(3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessForTransaction, isSuccess, isError, isErrorForTransaction])

  return { write, writeAsync }
}

export const useBridgeOutBsc = (setLoadSpinner: React.Dispatch<React.SetStateAction<number>>) => {
  const address = Config.contractAddressBridgeBsc();
  const {
    data,
    error,
    write,
    writeAsync,
    isError,
    isSuccess,
  } = useContractWrite({
    address: address as `0x${string}`,
    abi: ABI_BSC,
    functionName: 'bridgeOut'
  })

  const { chainFromStatus, chainToStatus } = useAppSelector((state: any) => state.bridge)

  const { isSuccess: isSuccessForTransaction, isError: isErrorForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    enabled: chainFromStatus === BridgeLocation.Game || chainToStatus === BridgeLocation.Game,
  })

  useEffect(() => {
    if (chainFromStatus === BridgeLocation.Game && chainToStatus === BridgeLocation.BSC) {
      if (isSuccess) setLoadSpinner(2)
      if (isSuccessForTransaction) setLoadSpinner(0)
      if (isError) {
        notifyError(`Constract Error BridgeOut from ${chainFromStatus} to ${chainToStatus}.`, error, 26)
        setLoadSpinner(3)
      }
      if (isErrorForTransaction) {
        notifyError(`Transaction Error BridgeOut from ${chainFromStatus} to ${chainToStatus}.`, error, 27)
        setLoadSpinner(3)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessForTransaction, isSuccess, isError, isErrorForTransaction])

  return { write, writeAsync }
}

export const useSetApprovalDpsEth = () => {
  const address = Config.contractAddressTokenEth()
  return useContractWrite({
    address: address as `0x${string}`,
    abi: ERC20_ABI,
    functionName: 'approve',
  })
}

export const useGetApprovalDpsEth = () => {
  const senderAddress = Config.contractAddressBridgeEth()
  const address = Config.contractAddressTokenEth()
  const { address: ownerAddress } = useAccount()
  return useContractRead({
    address: address as `0x${string}`,
    abi: ERC20_ABI,
    functionName: 'allowance',
    args: [ownerAddress, senderAddress],
    watch: true,
    cacheTime: 60_000,
    enabled: Boolean(senderAddress && ownerAddress),
  })
}

export const useIsApprovedForAllBsc = () => {
  const senderAddress = Config.contractAddressBridgeBsc()
  const address = Config.contractAddressResourcesBsc()
  const { address: ownerAddress } = useAccount()

  return useContractRead({
    address: address as `0x${string}`,
    abi: RESOURCES_ABI,
    functionName: 'isApprovedForAll',
    args: [ownerAddress, senderAddress],
    watch: true,
    cacheTime: 60_000,
    enabled: Boolean(senderAddress && ownerAddress),
  })
}

export const useSetApprovalForAllBsc = () => {
  const address = Config.contractAddressResourcesBsc()
  return useContractWrite({
    address: address as `0x${string}`,
    abi: RESOURCES_ABI,
    functionName: 'setApprovalForAll',
  })
}

export const useSetApprovalDpsBsc = () => {
  const address = Config.contractAddressTokenBsc()
  return useContractWrite({
    address: address as `0x${string}`,
    abi: ERC20_ABI,
    functionName: 'approve',
  })
}

export const useGetApprovalDpsBsc = () => {
  const senderAddress = Config.contractAddressBridgeBsc()
  const address = Config.contractAddressTokenBsc()
  const { address: ownerAddress } = useAccount()
  return useContractRead({
    address: address as `0x${string}`,
    abi: ERC20_ABI,
    functionName: 'allowance',
    args: [ownerAddress, senderAddress],
    watch: true,
    cacheTime: 60_000,
    enabled: Boolean(senderAddress && ownerAddress),
  })
}

