import React, { useEffect, useState } from 'react'
import { AiOutlineLoading } from 'react-icons/ai'
import axios from 'axios'
import config from '@/config/index'

interface ServiceStatusProps {
  className?: string
}

interface ServerInfo {
  server_environment: string
  global_server_status: string
}

export default function ServiceStatus(props: ServiceStatusProps): JSX.Element {
  const [serverStatus, setServerStatus] = useState<string | null>(null)

  useEffect(() => {
    checkService()
  }, [])

  const checkService = () => {
    axios.get(config.STATUS_API_URL).then((resp) => {
      const server_list = resp?.data?.servers_list
      if (server_list.length > 0) {
        server_list.map((item: ServerInfo) => {
          if ('PRODUCTION' === item.server_environment.toUpperCase()) {
            setServerStatus(item.global_server_status)
          }
        })
      }
    })
  }

  return (
    <p className={`text-info ${props.className}`}>
      Game Status: {serverStatus ? serverStatus : <AiOutlineLoading className="animate-spin ml-2" />}
    </p>
  )
}
