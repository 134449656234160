import React from "react";
import BnbIcon from "@/components/Svg/Icons/BnbIcon";
import { BridgeLocation } from "@/entities/bridgeLocation";
import UnityIcon from "@/components/Svg/Icons/UnityIcon";
import EthIcon from "@/components/Svg/Icons/EthIcon";

interface BridgeLocationIconProps {
  location: BridgeLocation;
}

const BridgeLocationIcon: React.FC<BridgeLocationIconProps> = ({
  location,
}) => {
  switch (location) {
    case BridgeLocation.BSC:
      return <BnbIcon />;
    case BridgeLocation.ETH:
      return <EthIcon width="14px" />;
    case BridgeLocation.Game:
      return <UnityIcon />;
    default:
      return null;
  }
};

export default BridgeLocationIcon;
