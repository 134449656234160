import { useEffect } from 'react'
import { styled } from 'styled-components'
import ResourceList from './ResourceList'
import useSWR from 'swr'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { BridgeLocation } from '@/entities/bridgeLocation'
import { useAccount } from 'wagmi'
import { FetchBridgeOutData } from '@/utils/apiFetching'
import { useResources } from '@/hooks/useResource'
import { resetConfirmResource, setResourceBalance } from '@/features/bridge/bridgeSlice'
import { RESOURCE_LIST } from '@/utils/resourceHelper'

const compareResouceBalance = (
  balance:any[],
  origin:any[],
  confirm:any[],
) => {
  return (
    balance.length === origin.length &&
    origin.every((originElement:any) => {
      const balanceElement = balance.find(e => e.pid === originElement.pid)
      const confirmElement = confirm.find(e => e.pid === originElement.pid)
      if (balanceElement && confirmElement) {
        return originElement.quantity === balanceElement.quantity + confirmElement.quantity
      } if (balanceElement){
        return originElement.quantity === balanceElement.quantity
      }
      console.log(originElement, balanceElement, confirmElement)
      return false
    })
  )
}
const AvailableResource = () => {
  const dispatch = useAppDispatch()
  const { address } = useAccount()
  const { chainFromStatus, chainToStatus, resourceBalance, confirmResource } = useAppSelector((state: any) => state.bridge)

  const enabled =
    (chainFromStatus === BridgeLocation.Game && chainToStatus === BridgeLocation.BSC) ||
    (chainToStatus === BridgeLocation.Game && chainFromStatus === BridgeLocation.BSC)

  const { data: bridgeOutAssets } = useSWR([`BridgeOut`, chainToStatus, address], () =>
    FetchBridgeOutData(chainToStatus as BridgeLocation, address as string)
  )

  const resources = useResources(enabled)

  useEffect(() => {
    if (chainFromStatus === BridgeLocation.Game) {
      const getResource: string[] = []
      const _resources = bridgeOutAssets?.bridgableItems?.resources ?? []
      RESOURCE_LIST.map((item: any) => {
        const filteredResources = _resources?.filter((k: any) => k.resourceId === item.pid)
        const netItem = {
          ...item,
          quantity: filteredResources?.length ? Number(filteredResources[0].balance) : Number(0),
        }
        getResource.push(netItem)
      })
      if (!compareResouceBalance([...resourceBalance], [...getResource], [...confirmResource])){
        dispatch(resetConfirmResource())
        dispatch(setResourceBalance(getResource))
      }
    } else {
      if (!compareResouceBalance([...resourceBalance], [...resources], [...confirmResource])){
        dispatch(resetConfirmResource())
        dispatch(setResourceBalance(resources))
      }
    }
  }, [chainFromStatus, chainToStatus, resources, bridgeOutAssets,  dispatch])

  return (
    ((chainFromStatus === BridgeLocation.Game && chainToStatus === BridgeLocation.BSC) ||
      (chainToStatus === BridgeLocation.Game && chainFromStatus === BridgeLocation.BSC)) && (
      <div className="resource">
        <AmountTitle>Available Resources</AmountTitle>
        <div className="available-resource">
          <ResourceList />
        </div>
      </div>
    )
  )
}

export default AvailableResource

const AmountTitle = styled.span`
  font-size: 18px;
`
