import { IoIosRocket } from 'react-icons/io'
import React from 'react'
import { SiGitbook } from 'react-icons/si'

export interface NavigationEntry {
  label: string
  href: string
  isActive: boolean
  icon: JSX.Element | null
  chain: 'ETH' | 'BNB' | null
}

export const navigationEntries: NavigationEntry[] = [
  {
    label: 'Land Deeds',
    href: 'https://land.deepspace.game/',
    chain: 'ETH',
    isActive: false,
    icon: null,
  },
  {
    label: 'Outpost',
    href: 'https://app.deepspace.game/outpost/ships',
    chain: 'BNB',
    isActive: false,
    icon: null,
  },
  {
    label: 'Inventory',
    href: 'https://app.deepspace.game/inventory/ships',
    chain: 'BNB',
    isActive: false,
    icon: null,
  },
  {
    label: 'Shipyard',
    href: 'https://app.deepspace.game/shipyard/ships',
    chain: 'BNB',
    isActive: false,
    icon: null,
  },
  {
    label: 'Vault',
    href: 'https://app.deepspace.game/staking',
    chain: 'BNB',
    isActive: false,
    icon: null,
  },
  {
    label: 'Bridge',
    href: '/',
    chain: null,
    isActive: true,
    icon: null,
  },
  {
    label: 'Play',
    href: 'https://app.deepspace.game/play',
    chain: null,
    isActive: false,
    icon: <IoIosRocket fontSize={20} />,
  },
  {
    label: 'Docs',
    href: 'https://docs.deepspace.game/deepspace-dps',
    chain: null,
    isActive: false,
    icon: <SiGitbook fontSize={20} />,
  },
]
