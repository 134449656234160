import { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import QuantityInputBridgeIn from './QuantityInputBridgeIn'
import { useAppDispatch, useAppSelector } from 'src/redux/hook'
import { useNetwork, useBalance, useAccount } from 'wagmi'
import { setNativeBalance, setConfirmNative } from '@/features/bridge/bridgeSlice'
import useSWR from 'swr'
import { FetchBridgeOutData } from '@/utils/apiFetching'
import { BridgeLocation } from '@/entities/bridgeLocation'

const AvailableNative = () => {
  const dispatch = useAppDispatch()
  const { chain } = useNetwork()
  const { address } = useAccount()
  const { chainFromStatus, chainToStatus, nativeBalance } = useAppSelector((state: any) => state.bridge)

  const balanceFromChain = useBalance({ address, watch: true })

  const [symbol, setSymbol] = useState('ETH')
  const [bridgeInNative, setBridgeInNative] = useState('')
  const [availNative, setAvailNative] = useState(0)

  const { data: bridgeOutAssets } = useSWR([`BridgeOut`, chainToStatus, address], () =>
    FetchBridgeOutData(chainToStatus as BridgeLocation, address as string)
  )
  
  useEffect(() => {
    let nativeBalance = '0'
    if (chainFromStatus === BridgeLocation.Game ) {
      if (bridgeOutAssets)
        nativeBalance = (
          Math.floor(
            parseFloat(bridgeOutAssets?.bridgableItems?.nativeTokenBalance ?? '0') /
            Math.pow(10, Number(balanceFromChain.data?.decimals)) * 10000
          )/10000
        ).toFixed(4)
    } else if (chainToStatus && balanceFromChain) {
      nativeBalance = (Math.floor(parseFloat(balanceFromChain?.data?.formatted ?? '0') * 10000)/10000).toFixed(4)
    }
    dispatch(setNativeBalance(Number(nativeBalance)))
  }, [chainFromStatus, chainToStatus, bridgeOutAssets, balanceFromChain, dispatch])

  useEffect(() => {
    if (chain) {
      setSymbol(chain.nativeCurrency.symbol)
    }
  }, [chain])

  useEffect(() => {
    setAvailNative(nativeBalance)
    setBridgeInNative('')
    dispatch(setConfirmNative('Confirm'))
  }, [nativeBalance, dispatch])

  const onChangeNativeQuantity = (e: any) => {
    const re = /^(?:\d+(?:\.\d{1,4})?|\.\d{1,4})?$/
    if (e.target.value === '') {
      setAvailNative(nativeBalance)
      setBridgeInNative('')
      dispatch(setConfirmNative('Confirm'))
    } else {
      if (re.test(e.target.value)) {
        if (nativeBalance >= Number(e.target.value)) {
          const calAvailableDps = (nativeBalance - Number(e.target.value)).toFixed(4)
          setAvailNative(Number(calAvailableDps))
          setBridgeInNative(e.target.value)
          dispatch(setConfirmNative(Number(e.target.value).toString()))
        }
      }
    }
  }

  const onClickMaxNative = () => {
    setAvailNative(0)
    dispatch(setConfirmNative(nativeBalance.toString()))
    setBridgeInNative(nativeBalance.toString())
  }

  return (
    (chainFromStatus === BridgeLocation.Game || chainToStatus === BridgeLocation.Game) && (
      <>
        <AmountTitle>Available {symbol}</AmountTitle>
        <div className="available-dps">
          <AmountContainer>
            <Amount></Amount>
            <AmountValue>
              {' '}
              {availNative} {symbol}
            </AmountValue>
          </AmountContainer>
          <Spancer></Spancer>
          <QuantityInputBridgeIn
            onChange={onChangeNativeQuantity}
            onClick={onClickMaxNative}
            bridgeInDps={bridgeInNative}
          ></QuantityInputBridgeIn>
        </div>
      </>
    )
  )
}

export default AvailableNative

const AmountTitle = styled.span`
  font-size: 18px;
`
const AmountContainer = styled.div`
  width: 190px;
  height: 30px;
  border: solid 2px #00aeee;
  position: relative;
`
const Amount = styled.div`
  width: 188px;
  height: 28px;
  background: #00aeee;
  opacity: 0.6;
`
const AmountValue = styled.span`
  position: absolute;
  top: 2px;
  color: white;
  left: 3px;
`
const Spancer = styled.div`
  width: 60px;
  height: 1px;
  background: #00aeee;
`
