export enum CoreType {
    FISSION = 'fission',
    MAGNETIC = 'magnetic',
    MECHANICAL = 'mechanical',
    PLASMA = 'plasma',
  }
  
  export enum ShipType {
    FIGHTER = 'fighter',
    SUPPORT = 'support',
    MINING = 'mining',
    TANK = 'tank'
  }
  
  export enum TextureType {
    COMMON = 'common',
    UNCOMMON = 'uncommon',
    RARE = 'rare',
    LEGENDARY = 'legendary',
    MYTHIC = 'mythic'
  }

  export const TextureColors = ['#ededed', '#7fc766', '#00bdff', '#ff9a54', '#ff00d2']

  export const SHIP_TYPES = ['Fighter', 'Support', 'Tank', 'Mining']