import { useState, useEffect } from 'react'
import { useAppSelector } from 'src/redux/hook'
import BnbIcon from '@/components/Svg/Icons/BnbIcon'
import EthIcon from '@/components/Svg/Icons/EthIcon'
import { styled } from 'styled-components'
import { useNetwork } from 'wagmi'
import { BridgeLocation } from '@/entities/bridgeLocation'

const ConfirmNative = () => {
  const { chain } = useNetwork()

  const { chainFromStatus, chainToStatus, confirmNative } = useAppSelector((state: any) => state.bridge)

  const [symbol, setSymbol] = useState('ETH')

  useEffect(() => {
    if (chain) {
      setSymbol(chain.nativeCurrency.symbol)
    }
  }, [chain])

  return (
    (chainFromStatus === BridgeLocation.Game || chainToStatus === BridgeLocation.Game) && (
      <ConfirmDpaWapper>
        <ShdowConfirmDps></ShdowConfirmDps>
        <DpsValueContainer>
          <DpsIcon>
            {symbol === 'BNB' || (symbol === 'tBNB' && <BnbIcon height={25} />)}
            {symbol === 'ETH' && <EthIcon height={25} />}
            <DpsTitle>{symbol}</DpsTitle>
          </DpsIcon>
          <DpsTitle>
            {confirmNative} {confirmNative === 'Confirm' && symbol}
          </DpsTitle>
        </DpsValueContainer>
      </ConfirmDpaWapper>
    )
  )
}

export default ConfirmNative

const ConfirmDpaWapper = styled.div`
  width: 100%;
  height: 30px;
  border: solid 1px #00aeee;
  margin-top: 10px;
  position: relative;
`
const ShdowConfirmDps = styled.div`
  width: 100%;
  height: 30px;
  background: black;
  opacity: 0.3;
`
const DpsValueContainer = styled.div`
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  padding-top: 2px;
`

const DpsTitle = styled.span``
const DpsIcon = styled.span`
  display: flex;
  align-items: center;
  width: 80px;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 40px;
`
