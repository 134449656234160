import { BsChevronRight } from 'react-icons/bs'
import { Connector, useConnect } from 'wagmi'
import { toast } from 'react-toastify';
import MetamaskIcon from '../Svg/Icons/MetamaskIcon'
import WalletConnectIcon from '../Svg/Icons/WalletConnectIcon'


interface WalletProviderBtnProps {
  connector: Connector,
  onClose: () => void
}

export default function WalletProviderBtnProps(props: WalletProviderBtnProps): JSX.Element {
  const { connect, error } = useConnect()

  const connector  = props.connector

  const handleClickConnectorButton = () => {
    try{
      connect({ connector })
    }
    catch(error){
      console.log(JSON.stringify(error))
      toast("You failed to connect the chain. Please try again")
    }
    
    props.onClose()
  }
  return (
    <>
      <button
        className="flex"
        key={props.connector.id}
        onClick={handleClickConnectorButton}
      >
        <div>
          {connector.id === "injected" ? <MetamaskIcon width={40} /> : <WalletConnectIcon width={40} />}
        </div>
        <div className="flex-auto text-lg font-bold">{props.connector.name}</div>
        <BsChevronRight fontSize={20} />
      </button>
      {error && <div>{error.message}</div>}
    </>
  )
}
