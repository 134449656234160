import React, { useState } from 'react'
import { BridgeLocation } from '@/entities/bridgeLocation'
import Image from 'next/image'
import { useUpdateEffect } from 'usehooks-ts'

export interface SelectOption {
  value: BridgeLocation
  label: string
  icon: JSX.Element
}

interface BridgeLocationDropdownProps {
  options: SelectOption[]
  changedOptions:SelectOption[]
  defaultSelectedValue: BridgeLocation
  onChange: (value: BridgeLocation) => void
}

const BridgeLocationDropdown: React.FC<BridgeLocationDropdownProps> = ({ options,changedOptions, defaultSelectedValue, onChange }) => {
  let defaultSelectedOption = options.find((o) => o.value === defaultSelectedValue) //-----current selected------
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption) 
  
  const handleSelectChange = (option: SelectOption) => {
    onChange(option.value)    //--------<Upload selected>-----------
    setSelectedOption(option)  //-------<selected>-------
  }

  useUpdateEffect(() => {
    defaultSelectedOption = changedOptions.find((o) => o.value === defaultSelectedValue)
    setSelectedOption(defaultSelectedOption)
  }, [defaultSelectedValue])

  return (
    <div className="dropdown dropdown-hover">
      <div className="flex items-center px-4 py-2 text-sm">
        {!selectedOption ? (
          <>
            <span>Select</span>
          </>
        ) : (
          <>
            {selectedOption.icon}
            <span className="ml-2">{selectedOption.label}</span>
          </>
        )}

        <Image className="ml-2" src="icons/caret-down-fill.svg" width={15} height={15} alt="SVG"></Image>
      </div>
      <ul tabIndex={0} className="p-2 shadow dropdown-content menu bg-base-100 rounded-box w-52">
        {options
          .filter((o) => o.label)
          .map((option) => (
            <li key={`dd-${option.value}`} value={option.value}>
              <a
                key={option.value}
                className="flex items-center px-4 py-2 text-sm"
                onClick={() => handleSelectChange(option)}
              >
                <span key={`dd-icon-${option.value}`}>{option.icon}</span>
                <span key={`dd-label-${option.value}`} className="ml-2">
                  {option.label}
                </span>
              </a>
            </li>
          ))}
      </ul>
    </div>
  )
}

export default BridgeLocationDropdown
