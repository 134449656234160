import { styled } from 'styled-components'
import ConfirmResourceList from './ConfirmResourceList'
import { useAppSelector } from 'src/redux/hook'
import { BridgeLocation } from '@/entities/bridgeLocation'

const ConfirmResource = () => {
  const { chainFromStatus, chainToStatus } = useAppSelector((state: any) => state.bridge)

  return (
    ((chainFromStatus === BridgeLocation.Game && chainToStatus === BridgeLocation.BSC) ||
      (chainToStatus === BridgeLocation.Game && chainFromStatus === BridgeLocation.BSC)) && (
      <div className="confirm-resource">
        <AmountTitle>Resources</AmountTitle>
        <ConfirmResourceList />
      </div>
    )
  )
}

export default ConfirmResource

const AmountTitle = styled.span`
  font-size: 18px;
`
