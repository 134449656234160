import toast from 'react-hot-toast'

const TOAST_CONFIG = {
    style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
    }
}

export function toastSuccess (message: string): void {
    toast.error(message, TOAST_CONFIG)
}
export function toastError (message: string): void {
    toast.error(message, TOAST_CONFIG)
}