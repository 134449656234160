import { BsTwitter } from 'react-icons/bs'
import { FaDiscord, FaGithub, FaMediumM, FaRedditAlien, FaTelegramPlane, FaYoutube } from 'react-icons/fa'

export interface SocialEntry {
  label: string
  href: string
  icon: JSX.Element
}

const iconSize = 22
export const socialEntries: SocialEntry[] = [
  {
    label: 'Twitter',
    href: 'https://dps.space/twitter',
    icon: <BsTwitter fontSize={iconSize} />,
  },
  {
    label: 'Telegram',
    href: 'https://dps.space/telegram',
    icon: <FaTelegramPlane fontSize={iconSize} />,
  },
  {
    label: 'Discord',
    href: 'https://dps.space/discord',
    icon: <FaDiscord fontSize={iconSize} />,
  },
  {
    label: 'Reddit',
    href: 'https://dps.space/reddit',
    icon: <FaRedditAlien fontSize={iconSize} />,
  },
  {
    label: 'Medium',
    href: 'https://blog.deepspace.game/',
    icon: <FaMediumM fontSize={iconSize} />,
  },
  {
    label: 'Youtube',
    href: 'https://dps.space/youtube',
    icon: <FaYoutube fontSize={iconSize} />,
  },
  {
    label: 'Github',
    href: 'https://dps.space/github',
    icon: <FaGithub fontSize={iconSize} />,
  },
]
