import { BridgeLocation } from '../entities/bridgeLocation'
import { BridgeOutGetResponse } from '../entities/dto/bridgeOut'
import { ShipInfo } from '../entities/dto/ship'
import { Config } from '@/config/config'

const bridgeLocationToChainString = (bridgeLocation: BridgeLocation): string => {
  switch (bridgeLocation) {
    case BridgeLocation.BSC:
      return 'bsc'
    case BridgeLocation.ETH:
      return 'eth'
    default:
      throw `Uknown chain ${bridgeLocation}`
  }
}

export const FetchBridgeInData = async (bridgeLocation: BridgeLocation, address: string): Promise<ShipInfo[]> => {
  if (address) {
    const chainString = bridgeLocationToChainString(bridgeLocation)
    const url = `${Config.backendUrl()}/api/BridgeIn/${chainString}/${address}`
    const response = await fetch(url)
    const data = await response.json()
    return data as ShipInfo[]
  } else {
    return []
  }
}

export const FetchBridgeOutData = async (
  bridgeLocation: BridgeLocation,
  address: string,
): Promise<BridgeOutGetResponse> => {
  if (address) {
    const chainString = bridgeLocationToChainString(bridgeLocation)
    const url = `${Config.backendUrl()}/api/BridgeOut/${chainString}/${address}`

    const response = await fetch(url)
    const data = await response.json()
    return data as BridgeOutGetResponse
  } else {
    return {} as BridgeOutGetResponse
  }
}
