import React from 'react'
import { Svg } from '../index'
import { SvgProps } from '../types'

export default function BnbIcon(props: SvgProps): JSX.Element {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.745399 24" {...props}>
        <path
          d="M 4.00392,3.6761 10.3727,0 16.7415,3.6761 14.4,5.03415 10.3727,2.7161 6.34539,5.03415 Z M 16.7415,8.31219 14.4,6.95415 10.3727,9.2722 6.34539,6.95415 4.00392,8.31219 v 2.71611 l 4.02731,2.318 v 4.6361 l 2.34147,1.3581 2.3415,-1.3581 v -4.6361 l 4.0273,-2.318 z m 0,7.35221 V 12.9483 L 14.4,14.3063 v 2.7161 z m 1.6624,0.96 -4.0273,2.318 v 2.7161 l 6.3688,-3.6761 v -7.3522 l -2.3415,1.3581 z M 16.0625,5.99415 18.4039,7.35219 V 10.0683 L 20.7454,8.71024 V 5.99415 L 18.4039,4.6361 Z M 8.03123,19.9259 V 22.642 L 10.3727,24 12.7142,22.642 v -2.7161 l -2.3415,1.358 z m -4.02731,-4.2615 2.34147,1.358 V 14.3063 L 4.00392,12.9483 Z M 8.03123,5.99415 10.3727,7.35219 12.7142,5.99415 10.3727,4.6361 Z M 2.34146,7.35219 4.68293,5.99415 2.34146,4.6361 0,5.99415 v 2.71609 l 2.34146,1.35806 z m 0,4.63611 L 0,10.6302 v 7.3522 l 6.36877,3.6761 v -2.7161 l -4.02731,-2.318 z"
          fill="currentColor"/>
    </Svg>
  )
}
