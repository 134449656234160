import React from 'react'
import BridgeLocationIcon from './BridgeLocationIcon'
import { BridgeLocation } from '@/entities/bridgeLocation'
import { BridgeStep } from '@/entities/bridgeStep'

interface BridgeStepsProps {
  bridgeSteps: Array<BridgeStep>
}

const BridgeSteps = (props: BridgeStepsProps) => {
  const { bridgeSteps } = props
  return (
    <div>
      <ul className="steps">
        {bridgeSteps.map((bridgeStep, index) => (
          <React.Fragment key={`step-${index}`}>
            {(bridgeStep.from !== BridgeLocation.Game || bridgeSteps.length === 1) && (
              <li className="step step-primary">
                <BridgeLocationIcon location={bridgeStep.from} />
              </li>
            )}
            <li className="step step-notprimary">
              <BridgeLocationIcon location={bridgeStep.to} />
            </li>
          </React.Fragment>
        ))}
      </ul>
    </div>
  )
}

export default BridgeSteps
