import { useEffect, useState } from 'react'
import { shortenAddress } from '@/utils/address'
import WalletConnectModal from '@/components/WalletConnectModal/WalletConnectModal'
import EthIcon from '../Svg/Icons/EthIcon'
import BnbIcon from '../Svg/Icons/BnbIcon'
import {
  useAccount,
  useDisconnect,
  useNetwork ,
} from 'wagmi'

const getChainIcon = (chain: number) => {
  const size = '100%'

  switch (chain) {
    case 1:
      return <EthIcon height={size} />
    case 5:
      return <EthIcon height={size} />
    case 56:
      return <BnbIcon height={size} />
    case 97:
      return <BnbIcon height={size} />
    default:
      return <></>
  }
}

export default function WalletConnectBtn() {
  const [isModalShown, setIsModalShown] = useState(false)

  const [isConnected, setIsConnected] = useState(false)

  const { address, isConnected: connectStatus } = useAccount()
  const { chain } = useNetwork()

  useEffect(() => {
    setIsConnected(connectStatus);
  }, [connectStatus])

  const { disconnect } = useDisconnect()

  if (isConnected) {
    return (
      <>
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="flex btn btn-accent btn-md flex-nowrap">
            <span className="relative flex w-4 h-4 mr-2">
              <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-success"></span>
              <span className="relative inline-flex w-4 h-4 rounded-full bg-success"></span>
            </span>
            <span className="relative flex w-6 h-6 mr-1 ">{chain && getChainIcon(chain.id)}</span>
            {address ? shortenAddress(address, 4) : ''}
          </label> 
          <ul tabIndex={0} className="p-2 mt-4 text-center shadow dropdown-content bg-base-100 rounded-box w-52">
            <button className="w-full btn btn-ghost" onClick={() => disconnect()}>
              Disconnect
            </button>
          </ul>
        </div>
      </>
    )
  }
  //const { open, close } = useWeb3Modal()
  return (
      <>
        <button className="flex btn btn-accent btn-md flex-nowrap" onClick={() => setIsModalShown(true)}>
          <span className="relative flex w-4 h-4 mr-2">
            <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-error"></span>
            <span className="relative inline-flex w-4 h-4 rounded-full bg-error"></span>
          </span>
          Connect Wallet
        </button>
        <WalletConnectModal isOpened={isModalShown} onClose={() => setIsModalShown(false)}/>
      </>
  )
}
