import React from 'react'
import styled from 'styled-components'
import { WagmiConfig, createConfig, configureChains} from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'

// import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

import { Toaster } from 'react-hot-toast'

import { Provider } from 'react-redux'
import { store } from 'src/redux/store'

import Header from '@/components/Layout/Header'
import Swap from '@/components/Bridge/Swap'
import BridgeArea from '@/components/Bridge/BridgeArea'
import Footer from '@/components/Layout/Footer'
import Drawer from '@/components/Layout/Drawer'
import { Config } from '@/config/config'


 
const { chains, publicClient, webSocketPublicClient  } = configureChains(
  [...Config.chains()],
  [publicProvider()],
  { pollingInterval: 10_000 },
)
const projectId = 'eb8e243732621415799f100d119a1c55' 
// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: 'MetaMask',
        shimDisconnect: true,
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId,
        showQrModal: true
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
})
//const ethereumClient = new EthereumClient(config, chains)
export default function BridgePage() {
  return (
    <>
      <WagmiConfig config={config}>
        <Provider store={store}>
          <Style className="drawer">
            <input id="drawer" type="checkbox" className="drawer-toggle" />
            <div
              className="pt-16 overflow-x-hidden drawer-content lg:pb-14 lg:justify-center"
              style={{ display: 'block' }}
            >
              <Header />
              <Swap /> 
              <BridgeArea />
              <Footer />
            </div>
            <Drawer />
            <Toaster />
          </Style>
        </Provider>
      </WagmiConfig>
    </>
  )
}

const Style = styled.div`
  .drawer-content {
    background: url('/images/background.webp');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow-y: auto;
    height: 100dvh;
  }
`
