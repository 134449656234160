import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'
import ChooseShipSkeleton from '@/components/SkeletonLoading/ChooseShipSkeleton'
import ShipItem from '@/components/Bridge/components/ShipItem'

const PaginatedItems = (props: any) => {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [currentItems, setCurrentItems] = useState([])
  const [pageCount, setPageCount] = useState(0)
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 20
  const { shipList } = props
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`)
    if (shipList) {
      setCurrentItems(shipList.slice(itemOffset, endOffset))
      setPageCount(Math.ceil(shipList.length / itemsPerPage))
    }
  }, [itemOffset, itemsPerPage, shipList])
  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = event.selected * itemsPerPage % shipList.length
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`)
    setItemOffset(newOffset)
  }
  const isMobile = useMediaQuery('(max-width: 1030px)')
  return (
    <>
      {shipList ? (
        <PageStyle>
          <div className="ships-container">
            {currentItems.map((item: any, key: number) => <ShipItem key={key} item={item} />)}
          </div>
          <nav className={isMobile ?  "mobile":"desktop"}>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={isMobile ? 1 : 3}
              marginPagesDisplayed={1}
              pageCount={pageCount}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </nav>
        </PageStyle>
      ) : (
        <ChooseShipSkeleton />
      )}
    </>
  );
}
export default PaginatedItems

const PageStyle = styled.div`
  .ships-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  nav {
    display:block;
    &.desktop {
      .page-link {
        padding-top:5px;
        font-size:21px;
      }  
    }
  }
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    justify-content: space-between;
    .page-link {
      position: relative;
      display: block;
      color: #00aeee;
      text-decoration: none;
      background-color: transparent;
      border: 0;
      transition: color .15s;
      font-size: 33px;
      width:44px;
      height:44px;
      &:hover {
        z-index: 2;
        color: #4ac3ef;
        background-color: transparent;
        border-color: #dee2e6;
      }
    
      &:focus {
        z-index: 3;
        color: #4ac3ef;
        background-color: transparent;
        outline: 0;
      }
    }
    
    .page-item {
      display:block;
      text-align: center;
      &:not(:first-child) .page-link {
        margin-left: -1px;
      }
    
      &.active .page-link {
        z-index: 3;
        color: #fff;
        background-color: transparent;
        border-color: 0;
      }
    
      &.disabled .page-link {
        color: #6c757d;
        pointer-events: none;
        background-color: transparent;
        border-color: #dee2e6;
      }
    }        
  }
`