import { AiOutlineClose } from 'react-icons/ai'
import { useConnect } from 'wagmi'
import WalletProviderBtnProps from '@/components/WalletProviderBtn/WalletProviderBtn'

interface WalletConnectModalProps {
    isOpened: boolean
    onClose: () => void
    onLogin?: () => void
}

export default function WalletConnectModal (props: WalletConnectModalProps): JSX.Element {
    const { connectors } = useConnect()
    return (
        <div className={`modal ${props.isOpened ? 'modal-open' : ''}`}>
            <div className="modal-box p-0 flex flex-col w-96 max-w-[90vw]">
                <div className="title flex items-center w-full py-3 px-5 relative">
                    <div className="flex-auto text-xl text-center">
                        Connect your wallet
                    </div>

                    <button className="absolute right-5 btn btn-xs btn-circle" onClick={props.onClose}>
                        <AiOutlineClose fontSize={16}/>
                    </button>
                </div>

                <div className="flex-auto overflow-y-auto">
                    <ul className="menu">
                        {connectors.map((connector) => (
                            <li key={connector.id}>
                                <WalletProviderBtnProps
                                    connector={connector}
                                    onClose = {props.onClose}
                                    />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}