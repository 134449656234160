import { styled } from "styled-components"
import Image from "next/image"
import { useAppSelector } from "src/redux/hook"

const ConfirmDPS = () => {
    const {
      confirmBalance,
    } = useAppSelector((state: any) => state.bridge)
  
    return (
        <ConfirmDpaWapper>
            <ShdowConfirmDps></ShdowConfirmDps>
            <DpsValueContainer>
                <DpsIcon>
                <Image unoptimized={true} src="/icons/icon-72x72.png" alt="" width={25} height={25} />
                <DpsTitle>DPS</DpsTitle>
                </DpsIcon>
                <DpsTitle>{confirmBalance}</DpsTitle>
            </DpsValueContainer>
        </ConfirmDpaWapper>
    )
}
export default ConfirmDPS

const ConfirmDpaWapper = styled.div`
  width: 100%;
  height: 30px;
  border: solid 1px #00aeee;
  margin-top: 10px;
  position: relative;
`
const ShdowConfirmDps = styled.div`
  width: 100%;
  height: 30px;
  background: black;
  opacity: 0.3;
`
const DpsValueContainer = styled.div`
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  padding-top: 2px;
`

const DpsTitle = styled.span``
const DpsIcon = styled.span`
  display: flex;
  align-items: center;
  width: 80px;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 40px;
`