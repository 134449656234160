import React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import { useAccount } from 'wagmi'
import Image from 'next/image'

import { useAppSelector, useAppDispatch } from 'src/redux/hook'
import { setModalFlag, setConfirmFlag } from '@/features/bridge/bridgeSlice'
import { BridgeLocation } from '@/entities/bridgeLocation'

export function detectBrowser() {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1) {
    return 'Opera'
  } else if (navigator.userAgent.indexOf('Chrome') != -1) {
    return 'Chrome'
  } else if (navigator.userAgent.indexOf('Safari') != -1) {
    return 'Safari'
  } else if (navigator.userAgent.indexOf('Firefox') != -1) {
    return 'Firefox'
  } else if (navigator.userAgent.indexOf('MSIE') != -1 || !!document.DOCUMENT_NODE == true) {
    return 'IE' //crap
  } else {
    return 'Unknown'
  }
}

interface BridgeConfirmModalProps {
  handleSetConfirmed: () => void,
  setApprovedClick:React.Dispatch<React.SetStateAction<boolean>>,
  isBridgeInApproved: boolean
}

export default function BridgeConfirmModal(props: BridgeConfirmModalProps) {
  const dispatch = useAppDispatch()
  const { chainToStatus, chainFromStatus } = useAppSelector((state: any) => state.bridge)
  const { handleSetConfirmed, setApprovedClick, isBridgeInApproved, } = props
  const browserType = detectBrowser()

  const { address: account } = useAccount()

  const handleCloseClick = (e: any) => {
    e.preventDefault()
    dispatch(setModalFlag(false))
    dispatch(setConfirmFlag(false))
    setApprovedClick(false)
  }

  const handleConfirm = () => {
    dispatch(setConfirmFlag(true))
    handleSetConfirmed()
  }
  const handleApprove= () => {
    setApprovedClick(true)
  }
  const isSwap = () => {
    return isBridgeInApproved && 
    (chainFromStatus === BridgeLocation.ETH && chainToStatus === BridgeLocation.BSC 
      || chainFromStatus === BridgeLocation.BSC && chainToStatus === BridgeLocation.ETH)
  }
  return (
    <StyledCard className="z-50 Modal">
      <div
        className={cn('sm:flex sm:flex-row body  text-black p-2 items-center mx-2 relative', {
          'browser-1': browserType !== 'Firefox',
          'browser-2': browserType === 'Firefox',
          'mobile-body-1': window.innerWidth <= 768 && browserType !== 'Firefox',
          'mobile-body-2': window.innerWidth <= 768 && browserType === 'Firefox',
        }, {
          'swap': isSwap()
        })}
      >
        <div className="items-center p-3 pt-5 pb-5 sm:flex sm:flex-row sm:pb-0 modal-container">
          <div className={cn('flex justify-start items-center text-base flex-col text-white w-full px-2', {})}>
            <div className="title-container">
              <span className="font-extrabold goldman-font" style={{ color: '#00ffff' }}>
                Bridge Into {`${chainToStatus}`}
              </span>
            </div>
            <div className="title-container">
              <span>
                Are you sure you want <br /> to bridge these items into {`${chainToStatus}`}?
              </span>
            </div>

            <div className="flex items-center justify-center button-container">
              <div className={cn('flex flex-row  w-full')}>
                <button
                  onClick={handleCloseClick}
                  className={cn('glass-button bg-white px-4 py-1 text-black font-bold')}
                >
                  <span className="glass-button-before"></span>
                  <span>Cancel</span>
                </button>
              </div>

              <div className={cn('flex flex-row  w-full')}>
                <button
                  onClick={() => {
                    isBridgeInApproved ? handleConfirm() : handleApprove()
                  }}
                  className={cn('glass-button bg-white px-4 py-1 text-black font-bold ml-2')}
                  style={!account ? { cursor: 'not-allowed', pointerEvents: 'all' } : {}}
                  disabled={!account ? true : false}
                >
                  <span className="glass-button-before"></span>
                  <span>{isBridgeInApproved ? 'Confirm':'Approve'}</span>
                </button>
              </div>
            </div>
            {isSwap() &&
              <div className="warning-container">
                <span>
                  <br />
                  <Image 
                    src="/images/warning.svg"
                    width={20}
                    height={20}
                    alt="warning icon"
                  />
                  <strong>WARNING:</strong> To use the bridge successfully cross-chain, you must hold both origination and destination chain currencies for gas fees (both BNB and ETH). If you do not have gas on the destination chain, your DPS will land in the “Game” and must be bridged again from there.
                </span>
              </div>
            }
          </div>
        </div>
      </div>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  max-height: 100vh;
  overflow: auto;
  font-size: 14px;
  .body {
    justify-content: center;
    width: auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .browser-1 {
    background-image: url('/images/ui/modals/modal-6.png');
  }
  .browser-2 {
    background-image: url('/images/ui/modals/modal_1.png');
  }
  .mobile-body-1 {
    background-image: url('/images/sign_card.png');
  }
  .mobile-body-2 {
    background-image: url('/images/sign_card_1.png');
  }
  .body .card-container {
    padding-right: 2px !important;
  }
  .mintAmount {
    width: 30px;
    margin-left: 12px;
    font-size: 18px;
    background-color: transparent;
    border-bottom: 2px solid white;
    text-align: center;
    color: cyan;
    font-weight: bold;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 6px;
    cursor: pointer;
  }
  .mintAmount option {
    background-color: white;
    cursor: pointer;
    color: #333;
    font-size: 14px;
  }
  .mintAmount:focus-visible {
    outline: unset !important;
  }

  .total-mint-value span {
    color: cyan;
    font-weight: bold;
  }
  .bridge-font-title {
    font-size: 15px;
  }

  .bridge-font {
    font-size: 16px;
  }
  .modal-container {
    width: 400px;
    height: 300px;
  }
  .swap {
    .modal-container {
      height: 400px;
    }
  }
  .title-container {
    margin-bottom: 20px;
  }
  .warning-container {
    img {
      display: inline-block;
      margin-right: 5px;
    }
  }
`
