import React, { useState } from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import { toastError } from '@/utils/toast'
import { useAppSelector, useAppDispatch } from 'src/redux/hook'
import { setConfirmResource } from '@/features/bridge/bridgeSlice'

const ResourceList = () => {
  const dispatch = useAppDispatch()

  const { resourceBalance } = useAppSelector((state: any) => state.bridge)

  const [active, setActive] = useState<number | null>(null)
  const [resouceNum, setResourceNum] = useState(0)

  const handleChange = (e: any) => {
    const re = /^[0-9\b]+$/
    if (re.test(e.target.value)) {
      setResourceNum(Number(e.target.value))
    }
    if (e.target.value === '') {
      setResourceNum(0)
    }
  }
  const handleAdd = (item: any) => {
    if (resouceNum === 0) {
      toastError('noSelectResourceNum')
    } else {
      if (resouceNum > item.quantity) {
        toastError('noAvailableResource')
      } else {
        dispatch(setConfirmResource({ item: item, resouceNum: resouceNum }))
        setResourceNum(0)
      }
    }
  }

  return (
    <>
      <Wrapper>
        <BackGround></BackGround>
        <List>
          {resourceBalance &&
            resourceBalance?.length > 0 &&
            resourceBalance.map((item: any, index: any) => {
              return (
                <div className="resource-container" key={index}>
                  <ResourceItem
                    onClick={() => {
                      if (active === index) {
                        setActive(null)
                      } else {
                        setActive(index)
                      }
                    }}
                  >
                    <Image src={item.img} width={25} height={25} alt="img" />
                    <Quantity>{item?.quantity}</Quantity>
                  </ResourceItem>
                  {active === index && (
                    <>
                      <div className="arrow-img" style={{ marginTop: '10px' }}>
                        <Image src="/asset/bridge/arrow.png" width={10} height={10} alt="arrow-img" />
                      </div>
                      <InputStyle className="input-num">
                        <Input
                          type="text"
                          placeholder="Quantity"
                          pattern="[0-9]*"
                          onChange={handleChange}
                          value={resouceNum}
                        />
                        <ADDBtn
                          onClick={() => {
                            handleAdd(item)
                          }}
                        >
                          ADD
                        </ADDBtn>
                      </InputStyle>
                    </>
                  )}
                </div>
              )
            })}
        </List>
      </Wrapper>
    </>
  )
}

export default ResourceList

const Wrapper = styled.div`
  width: 100%;
  height: 60px;
  border: solid 2px #00aeee;
  position: relative;
  .arrow-img {
    position: absolute;
    margin-left: 12px;
  }
  @media (max-width: 1030px) {
    .input-num {
      top: 51px;
    }
    .arrow-img>img{
      margin-left:15px
    }
  }
`
const BackGround = styled.div`
  width: 100%;
  height: 56px;
  background: black;
  opacity: 0.3;
`
const List = styled.div`
  width: 100%;
  height: 57px;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  top: 0px;
`
const ResourceItem = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border: solid 1px #00aeee;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
const Quantity = styled.span`
  font-size: 12px;
  position: absolute;
  right: 1px;
  bottom: -3px;
  font-family: monospace;
`
const InputStyle = styled.div`
  position: absolute;
  top: 70px;
  // width: 100px;
  // height: 20px;
  margin-left: -30px;
  border: 1px solid #00aeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Input = styled.input`
  outline: none !important;
  font-size: 12px;
  padding: 10px;
  width: 100px;
  height: 20px;
  background: #042535;
  color: white;
`

const ADDBtn = styled.button`
  font-size: 9px;
  position: absolute;
  width: 24px;
  height: 14px;
  right: -8px;
  background: #00aeee;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`
