import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'usehooks-ts'
import { AiOutlineSearch } from 'react-icons/ai'
import { useAppSelector } from 'src/redux/hook'
import { useAppDispatch } from '../../../redux/hook'
import { BridgeLocation } from '@/entities/bridgeLocation'
import PaginatedShips from '@/components/Bridge/components/PaginatedShips'

const AvailableShips = () => {
  const { availableShips, chainFromStatus, chainToStatus } = useAppSelector((state: any) => state.bridge)

  const [shipList, setShipList] = useState<any>([])

  const [height, setHeight] = useState('260px')
  const isMobile = useMediaQuery('(max-width: 1030px)')

  useEffect(() => {
    setShipList(availableShips)
  }, [availableShips])

  const onFilterShipByID = (e: any) => {
    const filteredShips = availableShips.filter((item: any) => item.tokenId.includes(e.target.value))
    setShipList(filteredShips)
  }
  useEffect(() => {
    if (chainFromStatus !== BridgeLocation.Game && chainToStatus !== BridgeLocation.Game) {
      setHeight(isMobile ? '301px' : '398px')
    } else {
      if (chainFromStatus === BridgeLocation.BSC || chainToStatus === BridgeLocation.BSC) {
        setHeight(isMobile ? '170px' : '250px')
      } else if (chainFromStatus === BridgeLocation.ETH || chainToStatus === BridgeLocation.ETH) {
        setHeight(isMobile ? '250px' : '340px')
      }
    }
  }, [chainFromStatus, chainToStatus, isMobile])

  return (
    <PageStyle>
      <div className="choose-ship goldman-font" style={{height: height}}>
        <div className="ship-container custom-scrollbar">
          <div className="ship-search">
            <span className="search-icon">
              <AiOutlineSearch />
            </span>
            <input type="text" className="serch-input" placeholder="ID SEARCH" onChange={(e) => onFilterShipByID(e)} />
          </div>
          <div>
            <PaginatedShips shipList={shipList} />
          </div>
        </div>
      </div>
    </PageStyle>
  )
}

export default AvailableShips

const PageStyle = styled.div`
    width: 100%;
    height:100%;
    .choose-ship{
      width: 100%;
      height: 250px;
      background: rgba(3, 58, 78, 0.85);
      border: 2px solid #00aeef;
      padding: 10px 10px 10px 10px;
    }
    .ship-container {
      width: 100%;
      height: 100%;
      padding-right: 10px;
      flex-wrap: wrap;
      overflow-y: scroll;
    }
    .cursor-pointer {
      width: 85px;
    }
    .dv-star-rating-star {
      font-size: 13px;
    }
    .custom-scrollbar::-webkit-scrollbar {
      width: 8px;
    }
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background: #00aeef; 
    }
    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
      background: #006f98; 
    }
    .custom-scrollbar::-webkit-scrollbar-button:single-button {
      background: #00aeef; 
      height: 6px;
    }
    .custom-scrollbar::-webkit-scrollbar-button:single-button:active {
      background: #006f98;
    }
    .ship-search {
      position: relative;
      outline: none !important;
      width: 110px;
      input::-webkit-input-placeholder { /* WebKit browsers */
        font-size: 12px;
      }
    }
    .serch-input {
      background: #021a22;
      outline: none !important;
      color: #ffffff;
      padding-left: 24px;
      width: 100%;
      height: 35px;
      border: solid 1px #006f98;
    }
    .search-icon {
      position: absolute;
      top: 11px;
      left: 7px;
    }
    .ship-detail {
      padding: 2px;
      font-size: 11px;
    }
    .ship-img{
      margin-top:10px;
      transition:.5s;
      background-color: rgba(0, 0, 0, 0.5);
      border: solid 2px #00aeef;
      width: 85px;
      height: 85px;
    }
    .ship-img:hover, .ship-img.active{
        background-color:#0279a5;
    }
    .choose-ship-rating{
        line-height:0.87;

        .dv-star-rating-star.dv-star-rating-full-star {
          color: #F9FF00 !important;
        }
    }
    .choose-ship-rating .dv-star-rating{
        font-size:21px;
        display:flex!important;
        align-items:center;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
    .core-type-section{
      top: 5px;
      right: 5px;
    }
  }
`
